import React, { useEffect, useState } from 'react';
import '../../Styles/Header.css';
import InstallPrompt from '../../InstallPrompt';

function Header() {
    const [topText, setTopText] = useState('');

    useEffect(() => {
        const fetchTopText = async () => {
            try {
                const response = await fetch('https://api.shubhamangala.in/getToptext.php');
                const data = await response.json();
                setTopText(data.home_scroll_text);
            } catch (error) {
                console.error('Error fetching the top text:', error);
            }
        };
        fetchTopText();
    }, []);

    return (
        <>
            <InstallPrompt />
            <div className='col-12 header-container'>
                <div className='header-flex'>
                    <div className='header-text'>
                    <p style={{ marginTop: '1%' }} dangerouslySetInnerHTML={{ __html: topText || 'Loading...' }} /> {/* Render HTML safely */}
                    </div>
                    {/* Uncomment this block if you have the images */}
                    {/* <div className='col-2'>
                        <div className='logo-container'>
                            <div>
                                <img width="100%" src={instagramimage} alt='Instagram' />
                            </div>
                            <div>
                                <img width="100%" src={instagramimage} alt='Instagram' />
                            </div>
                            <div>
                                <img width="100%" src={twitterimage} alt='Twitter' />
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    );
}

export default Header;
