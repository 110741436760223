import React from 'react'
import footerimage from '../assests/footerimage.png'
import '../Styles/Homefooter.css'
import facebookimage from '../assests/Facebook.png'
import linkedinimage from '../assests/Linkedin.png'
import twitterimage from '../assests/Twitter.png'
import instagramimage from '../assests/Instagram.png'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'


function Homefooter() {

    const location = useLocation()
    const navigate = useNavigate()

    const homehide = !['/myhome', '/myprofile','/aboutus','/terms','/privacypolicy'].includes(location.pathname)
    const onclicknavigate = (pathname) => {
        navigate(`/${pathname}`)

    }

    return (
        <div>
            <div style={{ backgroundColor: '#FFD7E4', marginTop: '2%' }}>
                {homehide && (
                    <div className="footerContainerone">
                        <div style={{ alignSelf: 'center' }} className='footer-cont'>
                            <span className="newsletter">NEWSLETTER</span>
                            <div>
                                <h2 className="inboxText">Find perfect matches in your inbox</h2>
                            </div>
                            <div style={{ marginTop: '2%' }}>
                                <span style={{ fontWeight: 'bold' }}>
                                    Join to get special offers, free giveaways, and once-in-a-lifetime deals.
                                </span>
                            </div>
                            <div className="inputContainer">
                                <div>
                                    <input className="inboxInput" type='text' placeholder='enter your email' />
                                </div>
                                <div>
                                    <button className="inboxBtn">Subscribe</button>
                                </div>
                            </div>
                        </div>
                        <div className="imageContainer">
                            <img width="80%" className="footerImage" src={footerimage} alt='No footer' />
                        </div>
                    </div>
                )}
            </div>
            <br />
            <div className="upgradeMembership">
                <div style={{ textAlign: 'center' }}>
                    <p style={{ fontWeight: 'bold', color: '#53962d' }}>UPGRADE MEMBERSHIP NOW</p>
                </div>
            </div>
            <div style={{ marginTop: '5%' }}>
                <div className="footerContainer">
                    <div style={{ width: '50%', marginTop: '3%' }}>
                        {/* <div>
                            <img className="footer-logo" src={logo} alt='No Footer img' />
                        </div>
                        <div style={{ marginTop: '3%' }}>
                            <span>
                                Shubhamangala is formed by a group of likeminded people to serve our community in the area of Matrimony having rich experience.
                            </span>
                        </div> */}
                    </div>
                    <div className="footerContent">
                        <div className="section">
                            <span style={{ fontWeight: 'bold' }}>Help And Support</span>
                            <div style={{ cursor:'pointer' }} onClick={()=>{onclicknavigate('aboutus')}} >About us</div>
                            <div>Shop</div>
                            <div>FAQ</div>
                            <div>Contact us</div>
                        </div>
                        <div className="section">
                            <span style={{ fontWeight: 'bold' }}>Terms & Policy</span>
                            <div style={{ cursor:'pointer' }} onClick={()=>{onclicknavigate('terms')}}>Terms & Conditions</div>
                            <div style={{ cursor:'pointer' }} onClick={()=>{onclicknavigate('privacypolicy')}}>Privacy Policy</div>
                            <div>Cookies</div>
                            <div>License</div>
                        </div>
                        <div className="section">
                            <span style={{ fontWeight: 'bold' }}>Need Help?</span>
                            <div>Blog</div>
                            <div>Report Misuse</div>
                            <div>Testimonials</div>
                            <div>Refunds</div>
                        </div>
                    </div>
                    <div style={{ marginTop: '2%', padding: '2%' }}>
                        <span style={{ fontWeight: 'bold' }}>Follow Us on</span>
                        <div className="socialIcons">
                            <img className="icon" src={facebookimage} alt='No facebook img' />
                            <img className="icon" src={linkedinimage} alt='No linkedin img' />
                            <img className="icon" src={twitterimage} alt='No twitter img' />
                            <img className="icon" src={instagramimage} alt='No instagram img' />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="copyRights">
                    <span style={{ fontWeight: 'bold' }}>Copyright ©2024 Mahaveltech All Rights Reserved.</span>
                    {/* <img width="50%" src={paymentimages} alt='No payment images' className='payment-images' /> */}
                </div>
            </div>
        </div>
    );
}


export default Homefooter
