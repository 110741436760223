import { takeLatest, call, put } from 'redux-saga/effects';
import axios from 'axios';
import { LOGIN_REQUEST, OTP_REQUEST, OTP_VERIFY_REQUEST } from './actionType';
import {
  loginSuccess,
  loginFailure,
  OtpSuccess,
  Otpfailure,
  OtpVerifySuccess,
  OtpVerifyFailure
} from './action';
import { Api } from '../../common/Api';

// const params = new URLSearchParams();
//     for (const key in action.payload) {
//       if (action.payload.hasOwnProperty(key)) {
//         params.append(key, action.payload[key]);
//       }
//     }
function* loginSaga(action) {
  try {
    const params = {
      loginID : action.payload.email_id,
      password: action.payload.password,
    }
    const response = yield call(axios.post, `${Api.Apiurl}login.php`, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    
    yield put(loginSuccess(response.data));
  } catch (error) {
    yield put(loginFailure(error.message));
  }
}

export function* watchLogin() {
  yield takeLatest(LOGIN_REQUEST, loginSaga);
}

function* OtpSaga(action) {
  const params = {
      mobile : action.payload.phonenumber
  }
  try {
      const response = yield call(axios.post, `${Api.Apiurl}loginWithOtp.php`, params, {
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
          },
      });
      yield put(OtpSuccess(response.data));
  } catch (error) {
      yield put(Otpfailure(error.message));
  }
}


export function* watchOtp() {
  yield takeLatest(OTP_REQUEST, OtpSaga);
}

function* VerifyOtpSaga(action) {
  const params = {
      mobile : action.payload.phonenumber,
      otp: action.payload.otp
  }
  try {
      const response = yield call(axios.post, `${Api.Apiurl}verifyOtp.php`, params, {
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
          },
      });
      yield put(OtpVerifySuccess(response.data));
  } catch (error) {
      yield put(OtpVerifyFailure(error.message));
  }
}


export function* watchVerifyOtp() {
  yield takeLatest(OTP_VERIFY_REQUEST, VerifyOtpSaga);
}