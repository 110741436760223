import React, { useState, useEffect } from 'react';
import { FaUser, FaEnvelope, FaLock, FaKey, FaCheck } from 'react-icons/fa';
import Registermodal from './Registermodal';
import { validateMail } from '../../../common/Common';

const Register = ({
  modalopen,
  closemodal,
  handleSubmit,
  handleChangeinput,
  formData,
  otpRequest,
  handleRegistersubmit,
  // handleSendEmail,
  emailSent,
  phoneSent,
}) => {

  // console.log('otpresule', otpresult)

  // const [emailSent, setEmailSent] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  // const [phoneSent, setPhoneSent] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);

  // useEffect(() => {
  //   if (otpresult) {
  //     if (otpresult.status === '1') {
  //       alert(otpresult.message);
  //       setEmailVerified(true); // Set emailVerified to true when status is '1'
  //     } else {
  //       alert('Failed to send OTP: ' + otpresult.message);
  //     }
  //   }
  // }, [otpresult]);
  const handleSendEmail = () => {
    if(formData.name === ''){
      alert("Please Enter name")
      return;
    }
    if (!formData.email || !validateMail(formData.email)) {
      alert('Please enter a valid email address');
      return;
    }
    otpRequest({
      email: formData.email
    })
  };

  const handleEmailVerify = () => {
    setEmailVerified(true);
  };

  const handleSendOTP = () => {
    otpRequest({
      mobile: formData.phonenumber
    })
  };

  const handlePhoneVerify = () => {
    setPhoneVerified(true);
  };

  const content = (
    <div className="modal-content">
      <div className="input-group-register">
        <div className="input-box-register">
          <FaUser className="input-icon" />
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChangeinput}
            className='Register-input-field'
          />
        </div>
      </div>
      <div className="input-group-register">
        <div className="input-box-register">
          <FaEnvelope className="input-icon" />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            disabled={emailVerified}
            onChange={handleChangeinput}
            className='Register-input-field'
          />
          {emailVerified && <FaCheck style={{ color: 'green', marginLeft: '5px' }} />}
        </div>
        {emailSent && (
          <div className="input-box-register">
            <FaKey className="input-icon" />
            <input
              type="number"
              name="otp"
              placeholder="Email OTP"
              value={formData.otp}
              onChange={handleChangeinput}
              disabled={emailVerified} // Disable input after verification
              style={{ width: '50%' }}
              className='Register-input-field'
            />
          </div>
        )}
      </div>
      {!emailSent && (
        <button className="submit-modal-button" onClick={handleSendEmail}>
          Send OTP to Email
        </button>
      )}
      {emailSent && !emailVerified && (
        <button className="submit-modal-button" onClick={handleEmailVerify}>
          Verify Email
        </button>
      )}
      {emailVerified && (
        <div className="input-group-register">
          <div className="input-box-register">
            <FaEnvelope className="input-icon" />
            <input
              type="text"
              name="phonenumber"
              placeholder="Phone Number"
              value={formData.phonenumber}
              disabled={phoneVerified}
              onChange={handleChangeinput}
              maxLength="10"
              className='Register-input-field'
            />
            {phoneVerified && <FaCheck style={{ color: 'green', marginLeft: '5px' }} />}
          </div>
          {phoneSent && (
            <div className="input-box-register">
              <FaKey className="input-icon" />
              <input
                type="text"
                name="mobileotp"
                placeholder="Phone OTP"
                value={formData.mobileotp}
                onChange={handleChangeinput}
                disabled={phoneVerified} // Disable input after verification
                style={{ width: '50%' }}
                className='Register-input-field'
              />
            </div>
          )}
        </div>
      )}
      {emailVerified && !phoneSent && (
        <button className="submit-modal-button" onClick={handleSendOTP}>
        Send OTP to Phone
        </button>
      )}
      {phoneSent && !phoneVerified && (
        <button className="verify-number-button" onClick={handlePhoneVerify}>
          Verify Phone Number
        </button>
      )}
      {phoneVerified && (
        <div className="input-group-register">
          <div className="input-box-register">
            <FaLock className="input-icon" />
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChangeinput}
              className='Register-input-field'
            />
          </div>
        </div>
      )}
      {phoneVerified && (
        <button className="submit-modal-button" onClick={handleRegistersubmit}>
          Submit
        </button>
      )}
    </div>
  );

  return (
    <>
      <Registermodal
        isOpen={modalopen}
        onClose={closemodal}
        title="Registration"
        content={content}
      />
    </>
  );
};

export default Register;
