// store.js
import { createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import {thunk} from 'redux-thunk';
import { rootSaga } from '../SagaRoot';
import loginReducer from '../Container/Login/Login-Reducer';
// import registerReducer from '../Container/Register/Register-reducer';
import {search} from '../Container/Home/Home-Reducer';
import Profile from '../Container/Profile/Profile-reducer';


// Combine all reducers
const rootReducer = combineReducers({
  login: loginReducer,
  // register: registerReducer,
  search,
  Profile,
});

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// Create the store with the reducers and middleware
const store = createStore(
  rootReducer,
  applyMiddleware(thunk,sagaMiddleware)
);

// Run the root saga
sagaMiddleware.run(rootSaga);

export default store;
