import { GET_MY_PROFILE_REQUEST, GET_MY_PROFILE_SUCCESS, GET_MY_PROFILE_FAILURE } from "./actionType";


export const getprofileRequest = (data)=>({
    type:GET_MY_PROFILE_REQUEST,
    payload:data,
  })
  export const getprofileSuccess = (data)=>({
    type: GET_MY_PROFILE_SUCCESS,
    payload: data
  })
  
  export const getprofileFailure = (error)=>({
    type: GET_MY_PROFILE_FAILURE,
    payload: error
  })