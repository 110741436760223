import React, { useState, useEffect } from 'react'
import Registermodal from './Registermodal'
import { Complexion, Physicalstatus, BodyType, EmployeIn } from '../../../common/Common'
import './Stepthree.css'

function Stepthree({
    isStepthreemodalopen,
    stepthreemodalclose,
    Height,
    profile_id,
    signupthree
}) {
    const [selectedcomplexion, setSelectedcomplexion] = useState("");
    const [selectedPhysicalstatus, setselectedPhysicalstatus] = useState("");
    const [selectedBodyType, setselectedBodyType] = useState("");
    const [HeightData, setHeightData] = useState([])
    const [SelectedWeight, setSelectedWeight] = useState("")
    const [selectedHeight, setSelectedHeight] = useState("");

    // console.log('selectedHeight', selectedHeight)

    useEffect(() => {
        if (Height !== undefined) {
            setHeightData(Height)
        }
    }, [Height])

    const weightOptions = Array.from({ length: 171 }, (_, i) => i + 30);


    const onstepthreesubmit = () => {
        if (!selectedHeight || !SelectedWeight || !selectedcomplexion || !selectedPhysicalstatus || !selectedBodyType) {
            alert("Please fill in all details before submitting.");
            return;
        }
        signupthree({
            height: selectedHeight,
            weight: SelectedWeight,
            complexion: selectedcomplexion,
            physicalStatus: selectedPhysicalstatus,
            bodytype: selectedBodyType,
            profile_id,
        })
    }

    const content = (
        <div className="step-three-container">
            <>
                <div className="step-three-input-group">
                    <div>
                        <div>
                            <span>Select Your Height</span>
                        </div>
                        <select
                            className="religion-dropdown"
                            style={{ width: '100%' }}
                            value={selectedHeight}
                            onChange={(e) => setSelectedHeight(e.target.value)}
                        >
                            {HeightData.map((item) => (
                                <option key={item.id} value={item.id}>
                                    <span style={{ color: 'black' }}>{item.height}</span>
                                </option>
                            ))}
                        </select>
                    </div>
                    <label className='step-three-label' htmlFor="weight">Weight</label>
                    <select className="religion-dropdown"
                        style={{ width: '100%' }}
                        value={SelectedWeight}
                        onChange={(e) => setSelectedWeight(e.target.value)}
                    >
                        <option value="" disabled>Select Your Weight</option>
                        {weightOptions.map((weight) => (
                            <option key={weight} value={weight}>
                                {weight} kg
                            </option>
                        ))}
                    </select>
                    <label className='step-three-label' htmlFor="complexion">Complexion</label>
                    <div style={{ display: 'flex', gap: '12px', flexWrap: 'wrap' }}>
                        {Complexion.map(option => (
                            <button
                                key={option}
                                className={`profile-option ${selectedcomplexion === option ? "selected" : ""}`}
                                onClick={() => setSelectedcomplexion(option)}
                            >
                                {option}
                            </button>
                        ))}
                    </div>
                </div>
                <div className="step-three-input-group">
                    <label className='step-three-label' htmlFor="complexion">Physical Status</label>
                    <div style={{ display: 'flex', gap: '12px', flexWrap: 'wrap' }}>
                        {Physicalstatus.map(option => (
                            <button
                                key={option}
                                className={`profile-option ${selectedPhysicalstatus === option ? "selected" : ""}`}
                                onClick={() => setselectedPhysicalstatus(option)}
                            >
                                {option}
                            </button>
                        ))}
                    </div>
                </div>
                <div className="step-three-input-group">
                    <label className='step-three-label' htmlFor="complexion">Body Type</label>
                    <div style={{ display: 'flex', gap: '12px', flexWrap: 'wrap' }}>
                        {BodyType.map(option => (
                            <button
                                key={option}
                                className={`profile-option ${selectedBodyType === option ? "selected" : ""}`}
                                onClick={() => setselectedBodyType(option)}
                            >
                                {option}
                            </button>
                        ))}
                    </div>
                </div>
                <div className="step-three-button-container">
                    <button className="submit-modal-button" onClick={onstepthreesubmit}>
                        Submit
                    </button>
                </div>
            </>
        </div>
    )

    return (
        <div>
            <Registermodal
                isOpen={isStepthreemodalopen}
                onClose={stepthreemodalclose}
                title="Step Three (Physical Status)"
                content={content}
            />
        </div>
    )
}

export default Stepthree
