import React from 'react';
import '../Styles/Pagination.css'

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    const handleClick = (page) => {
        if (page !== currentPage) {
            onPageChange(page);
        }
    };

    return (
        <div className="pagination-container">
            {pageNumbers.map((number, index) => {
                if (number === 1 || number === totalPages || 
                    (number >= currentPage - 1 && number <= currentPage + 1)) {
                    return (
                        <button
                            key={number}
                            onClick={() => handleClick(number)}
                            className={`pagination-button ${currentPage === number ? 'active' : ''}`}
                        >
                            {number}
                        </button>
                    );
                }
                if (number === 2 && currentPage > 3) {
                    return <span className='pagination-button' key={number}>...</span>;
                }
                if (number === totalPages - 1 && currentPage < totalPages - 2) {
                    return <span className='pagination-button' key={number}>...</span>;
                }
                return null;
            })}
        </div>
    );
};

export default Pagination;
