import React, { useState, useEffect } from 'react';
import Registermodal from './Registermodal';
import moment from 'moment';
import imageCompression from 'browser-image-compression';


function Stepsix({
    isStepsixmodalopen,
    stepsixmodalclose,
    stardata,
    Rasidata,
    signupSix,
    profile_id,
}) {


    const [dob, setDob] = useState(null);
    const [time, setTime] = useState('');
    const [rashi, setRashi] = useState('');
    const [nakshatra, setNakshatra] = useState('');
    const [Rasi, setRasi] = useState([])
    const [star, setstar] = useState([])
    const [file, setFile] = useState(null);


    useEffect(() => {
        if (Rasidata !== undefined) {
            setRasi(Rasidata)
        }
        if (stardata !== undefined) {
            setstar(stardata)
        }
    }, [Rasidata, stardata])


    const handleTimeChange = (e) => {
        setTime(e.target.value);
    };
    const handleDobChange = (e) => {
        setDob(e.target.value)
    }

    const handleRashiChange = (e) => {
        setRashi(e.target.value);
    };

    const handleNakshatraChange = (e) => {
        setNakshatra(e.target.value);
    };

    const isAdult = (dob) => {
        return moment().diff(moment(dob), 'years') >= 18;
    };

    const handleFileUpload = async (file, setImageState) => {
        const maxSizeMB = 10; // 1MB max size
        if (file.size / 1024 / 1024 > maxSizeMB) {
            console.log('File size exceeds 1MB. Please upload a smaller file.');
            return;
        }

        const options = {
            maxSizeMB: maxSizeMB,
            maxWidthOrHeight: 800,
            useWebWorker: true,
        };
        try {
            const compressedFile = await imageCompression(file, options);
            const imageUrl = URL.createObjectURL(compressedFile);
            setImageState(imageUrl);
        } catch (error) {
            alert('Error compressing image');
            console.error('Error compressing image:', error);
        }
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            handleFileUpload(selectedFile, setFile);
        }
    };

    const onclicksignupsix = () =>{
        if (!dob || !time || !nakshatra || !rashi || !file) {
            alert('Please fill in all fields and upload your horoscope.');
            return;
        }

        if (!isAdult(dob)) {
            alert('You must be at least 18 years old.');
            return;
        }

        signupSix({
            birthdate:dob,
            birthtime:time,
            star:nakshatra,
            moonsign:rashi,
            horoscope:file,
            profile_id,
        })
    }
    const content = (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label>Date of Birth:</label>
                <input
                    type="date"
                    value={dob}
                    onChange={handleDobChange}
                    style={{ padding: '5px' }}
                />
                {dob && !isAdult(dob) && <span style={{ color: 'red' }}>You must be at least 18 years old.</span>}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1%' }}>
                <label>Time of Birth:</label>
                <input
                    type="time"
                    value={time}
                    onChange={handleTimeChange}
                    style={{ padding: '5px' }}
                />
            </div>
            <div style={{ marginTop: '1%' }}>
                <div>
                    <span>Select Your Nakshatra</span>
                </div>
                <select
                    className="religion-dropdown"
                    value={nakshatra}
                    onChange={handleNakshatraChange}
                >
                    <option value="" disabled>Select Your Nakshatra</option>
                    {star.map((nakshatra) => (
                        <option key={nakshatra.id} value={nakshatra.id}>
                            {nakshatra.star}
                        </option>
                    ))}
                </select>
            </div>
            <div style={{ marginTop: '1%' }}>
                <div>
                    <span>Select Your Rasi</span>
                </div>
                <select
                    className="religion-dropdown"
                    value={rashi}
                    onChange={handleRashiChange}
                >
                    <option value="" disabled>Select Your Rasi</option>
                    {Rasi.map((rasi) => (
                        <option key={rasi.id} value={rasi.id}>
                            {rasi.rasi}
                        </option>
                    ))}
                </select>
            </div>
            <div style={{ marginTop: '1%' }}>
                <div>
                    <span>Upload your Horoscope</span>
                </div>
                <div style={{ display:'flex', flexDirection:'column' }}>
                    <input type='file' accept='.png,.jpeg,.jpg' onChange={handleFileChange} />
                    {file && (
                        <img src={file}  alt='noimg' width="30%" style={{ marginTop:'2%' }}/>
                    )}
                </div>
            </div>
            <div style={{ marginTop: '2%' }} className="step-three-button-container">
                <button className="submit-modal-button" onClick={onclicksignupsix}>
                    Submit
                </button>
            </div>
        </div>
    );

    return (
        <div>
            <Registermodal
                isOpen={isStepsixmodalopen}
                onClose={stepsixmodalclose}
                title="Step Six (Horoscope)"
                content={content}
            />
        </div>
    );
}

export default Stepsix;
