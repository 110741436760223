import React, { useEffect, useState } from 'react'
import Registermodal from './Registermodal'
import './Stepthree.css'
import { EmployeIn } from '../../../common/Common'

function StepFour({
    isStepfourmodalopen,
    stepfourmodalclose,
    Occupation,
    Education,
    signupfour,
    profile_id,
    Income
}) {

    const [Occupationdata, setOccupationdata] = useState([])
    const [educationdata, seteducationdata] = useState([])
    const [selectedEducation, setSelectedEducation] = useState("");
    const [selectedOccupation, setSelectedOccupation] = useState("");
    const [selectedEmploymentStatus, setSelectedEmploymentStatus] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [selectedIncome, setSelectedIncome] = useState("");
    const [Incomedata, setIncomedata] = useState([])

    
    useEffect(() => {
        if (Occupation !== undefined) {
            setOccupationdata(Occupation)
        }
        if (Education !== undefined) {
            seteducationdata(Education)
        }
        if(Income !== undefined){
            setIncomedata(Income)
        }
    }, [Occupation, Education,Income])

    const onsipupfoursubmit = () => {
        if (!selectedEducation || !selectedOccupation || !selectedEmploymentStatus || !companyName || !selectedIncome) {
            alert('Please fill in all fields.');
            return;
        }
        signupfour({
            edu_detail: selectedEducation,
            occupation: selectedOccupation,
            emp_in: selectedEmploymentStatus,
            company_name: companyName,
            income: selectedIncome,
            profile_id,
        });
    }

    const content = (
        <div>
            <>
                <div style={{ marginTop: '1%' }}>
                    <div>
                        <span>Select Education</span>
                    </div>
                    <select
                        className="religion-dropdown"
                        value={selectedEducation}
                        onChange={(e) => setSelectedEducation(e.target.value)}
                    >
                        {educationdata.map((item) => (
                            <option key={item.edu_id} value={item.edu_id}>
                                <span style={{ color: 'black' }}>{item.edu_name}</span>
                            </option>
                        ))}
                    </select>
                </div>
                <div style={{ marginTop: '1%' }}>
                    <div>
                        <span>Select Occupation</span>
                    </div>
                    <select
                        className="religion-dropdown"
                        value={selectedOccupation}
                        onChange={(e) => setSelectedOccupation(e.target.value)}
                    >
                        {Occupationdata.map((item) => (
                            <option key={item.ocp_id} value={item.ocp_id}>
                                <span style={{ color: 'black' }}>{item.ocp_name}</span>
                            </option>
                        ))}
                    </select>
                </div>
                <div style={{ marginTop: '1%' }}>
                    <div>
                        <span>Select Employement status</span>
                    </div>
                    <select
                        className="religion-dropdown"
                        value={selectedEmploymentStatus}
                        onChange={(e) => setSelectedEmploymentStatus(e.target.value)}
                    >
                        {EmployeIn.map((item, index) => (
                            <option key={index} value={item}>
                                <span style={{ color: 'black' }}>{item}</span>
                            </option>
                        ))}
                    </select>
                </div>
                <div style={{ marginTop: '1%' }}>
                    <div>
                        <span>Select Your Income (Annual)</span>
                    </div>
                    <select
                        className="religion-dropdown"
                        value={selectedIncome}
                        onChange={(e) => setSelectedIncome(e.target.value)}
                    >
                        <option value="" disabled>Select Your Income</option>
                        {Incomedata.map((income) => (
                            <option key={income.id} value={income.id}>
                                {income.income}
                            </option>
                        ))}
                    </select>
                </div>
                <div style={{ marginTop: '2%' }} className="step-three-button-container">
                    <button className="submit-modal-button" onClick={onsipupfoursubmit}>
                        Submit
                    </button>
                </div>
            </>
        </div>
    )
    return (
        <div>
            <Registermodal
                isOpen={isStepfourmodalopen}
                onClose={stepfourmodalclose}
                title="Step Four (Education & Occupation)"
                content={content}
            />
        </div>
    )
}

export default StepFour
