import React, { useEffect, useState } from 'react';
import './Styles/InstallPrompt.css'; // Import your CSS file for styling

const InstallPrompt = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstallPromptVisible, setIsInstallPromptVisible] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
      setIsInstallPromptVisible(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt(); // Show the install prompt
      const { outcome } = await deferredPrompt.userChoice; // Wait for the user's choice

      if (outcome === 'accepted') {
        console.log('User accepted the PWA install prompt');
      } else {
        console.log('User dismissed the PWA install prompt');
      }

      // Clear the deferredPrompt variable
      setDeferredPrompt(null);
      setIsInstallPromptVisible(false); // Hide the install button
    }
  };

  const handleClose = () => {
    setIsInstallPromptVisible(false); // Hide the install prompt
    setDeferredPrompt(null); // Clear the deferred prompt
  };

  return (
    <>
      {isInstallPromptVisible && (
        <div className="install-prompt-container">
          <div className="install-prompt-content">
            <button className="close-button" onClick={handleClose}>X</button>
            <p className='prompt-text'>Download our app for a better experience!</p>
            <button className="install-button" onClick={handleInstallClick}>
              Install App
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default InstallPrompt;
