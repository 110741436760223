import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import '../Forgotpassword/Forgotpassword.css'
import logo from '../../../assests/logo.png';

function ForgetPassword() {
    const [showPassword, setShowPassword] = useState(false);


    const handleCheckboxChange = () => {
        setShowPassword(prevShowPassword => !prevShowPassword);
    };
    return (
        <div>
        <div className='login-container-img'>
                <img src={logo} alt='no logo' className='login-image' />
            </div>
            <div className='forgotpassword-container'>
                <div className='login-container'>
                    <div className='login-img-before'></div>
                    <div className='forgot-box'>
                        <h2 style={{ color: '#f2682a', fontWeight: 'bold' }}>Forgot password</h2>
                        <div className='input-box'>
                            <FontAwesomeIcon icon={faEnvelope} className='icon' />
                            <input
                                type='text'
                                placeholder='Enter your phone number or Email'
                            // value={phonenumber}
                            // onChange={onChangePhone}
                            />
                        </div>
                        <div className='input-box'>
                            <FontAwesomeIcon icon={faLock} className='icon' />
                            <input
                                type='number'
                                placeholder='Enter your OTP'
                            // value={otp}
                            // onChange={onchangeotp}
                            />
                        </div>
                        <div className='input-box'>
                            <FontAwesomeIcon icon={faLock} className='icon' />
                            <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder='Enter your new password'
                            // value={password}
                            // onChange={onChangePassword}
                            />
                        </div>
                        <div className='input-box'>
                            <FontAwesomeIcon icon={faLock} className='icon' />
                            <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder='Enter your new password'
                            />
                            <FontAwesomeIcon 
                                icon={showPassword ? faEyeSlash : faEye} 
                                className='toggle-password-icon'
                                onClick={handleCheckboxChange} 
                            />
                        </div>
                        <div className='alternative-login'>
                            <button className='otp-login-btn'>
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgetPassword
