import { all, fork } from 'redux-saga/effects';
import * as loginsaga from '../src/Container/Login/Login-saga'
// import * as registersaga from '../src/Container/Register/Register-saga'
import * as homesaga from '../src/Container/Home/Home-Saga'
import * as profilesaga from './Container/Profile/Profile-saga'


const login = Object.values(loginsaga).map((saga) => {
    return fork(saga)
})

// const register = Object.values(registersaga).map((saga) => {
//     return fork(saga)
// })

const home = Object.values(homesaga).map((saga)=>{
    return fork(saga)
})

const profile = Object.values(profilesaga).map((saga)=>{
    return fork(saga)
})

export function* rootSaga() {
    yield all([
        ...login,
        // ...register,
        ...home,
        ...profile
    ])
}
