import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../Styles/Myhome.css';
// import Header from './Header';
import boyone from '../../assests/boyimg3.png';
import logo from '../../assests/logo.png';
import { FaSearch } from 'react-icons/fa';
import boy1 from '../../assests/boyimg3.png';
import boytwo from '../../assests/boyimg1.png';
import boythre from '../../assests/boyimg2.png';
import Homefooter from '../Homefooter';

function Myhome() {
    const [userdetails, setuserdetails] = useState('')
    const navigate = useNavigate() 

    useEffect(() => {
        const user = localStorage.getItem('user');
        if (user) {
            setuserdetails(JSON.parse(user));
        }
    }, [])

    const onclicknavigate = (pathname) =>{
        navigate(`/${pathname}`)

    }
    return (
        <div style={{ width: '100%' }}>
            <div className="container main-container">
            <div className='myprofile-header-content'>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: '20px' }}>Home</span>
                        <span style={{ marginRight: '20px' }}>Search</span>
                        <span>Membership</span>
                    </div>
                    <div  style={{ cursor:'pointer' }} onClick={()=>{onclicknavigate('/myhome')}}>
                        <img src={logo} alt='shubamangala' className='profile-header-logo' />
                    </div>
                    <div className='myhome-btn-container' style={{ display: 'flex' }}>
                        <button className='col-10 my-home-register-btn' onClick={()=>{onclicknavigate('register')}}>Register</button>
                        <button className='col-10 my-home-login-btn'  onClick={()=>{onclicknavigate('login')}}>Login</button>
                    </div>
                </div>
                <div>
                    <div className="col-md-12 profile-flex-container">
                        <div>
                            <div className="profile-card">
                                <div>
                                    <div className="profile-header">
                                        <img src={boyone} alt="John Doe" className="profile-pic" />
                                        <p>Hello {userdetails.username}</p>
                                        <p>{`(${userdetails.matri_id})`}</p>
                                    </div>
                                    <div className='profile-buttons'>
                                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                            <button className='profile-btn'>Shortlisted</button>
                                            <button className="matches">
                                                Matches <span className="notification">12</span>
                                            </button>
                                            <button className='profile-btn'>Settings</button>
                                        </div>
                                    </div>
                                    {/* Profile Stats Section */}
                                </div>
                            </div>
                            <div className="profile-stats">
                                <button className="stat-item">
                                    <p>Express Interest</p>
                                    <p>12</p>
                                </button>
                                <button className="stat-item">
                                    <p>Blocklisted Member</p>
                                    <p>0</p>
                                </button>
                                <button className="stat-item highlighted">
                                    <p>My Profile Viewed</p>
                                    <p>32</p>
                                </button>
                                <button className="stat-item">
                                    <p>Watched my mobile number</p>
                                    <p>2</p>
                                </button>
                            </div>
                        </div>
                        <div className='col-10' style={{ marginLeft: '8%' }}>
                            <div className="myhome-search-container">
                                <label htmlFor="search-id" className="search-label">Search By Id:</label>
                                <div className="search-wrapper">
                                    <input type="text" id="search-id" className="search-input" />
                                    <div>
                                        <FaSearch className="search-icon" />
                                    </div>
                                </div>
                                <div className="button-group">
                                    <div className='col-6'>
                                        <button className='message-btn'>Messages</button>
                                    </div>
                                    <div className='col-6'>
                                        <button className='myhome-profile-btn' onClick={()=>{onclicknavigate('myprofile')}}>My Profile</button>
                                    </div>
                                </div>
                            </div>
                            <div style={{ borderBottom: '2px solid gray', marginTop: '3%', width: '81%' }}></div>
                            <div>
                                <div style={{ padding: '0.5%' }}>
                                    <span style={{ fontSize: '20px' }}>RECENTLY JOINED</span>
                                </div>
                                <div className='col-10 flexItem'>
                                    <div>
                                        <img src={boy1} alt='noboyimg' className='myhome-images' />
                                        <div className='marginTop'>
                                            <button className='viewBtn-myhome'>View Profile</button>
                                        </div>
                                    </div>
                                    <div>
                                        <img src={boytwo} alt='noboyimg' className='myhome-images' />
                                        <div className='marginTop'>
                                            <button className='viewBtnTwo-myhome'>View Profile</button>
                                        </div>
                                    </div>
                                    <div>
                                        <img src={boythre} alt='noboyimg' className='myhome-images' />
                                        <div className='marginTop'>
                                            <button className='viewBtnThree-myhome'>View Profile</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ borderBottom: '2px solid gray', marginTop: '3%', width: '81%' }}></div>
                            <div>
                                <div style={{ padding: '0.5%' }}>
                                    <span style={{ fontSize: '20px' }}>FEATURED PROFILE</span>
                                </div>
                                <div className='col-10 flexItem'>
                                    <div>
                                        <img src={boy1} alt='noboyimg' className='myhome-images' />
                                        <div className='marginTop'>
                                            <button className='viewBtn-myhome'>View Profile</button>
                                        </div>
                                    </div>
                                    <div>
                                        <img src={boytwo} alt='noboyimg' className='myhome-images' />
                                        <div className='marginTop'>
                                            <button className='viewBtnTwo-myhome'>View Profile</button>
                                        </div>
                                    </div>
                                    <div>
                                        <img src={boythre} alt='noboyimg' className='myhome-images' />
                                        <div className='marginTop'>
                                            <button className='viewBtnThree-myhome'>View Profile</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ borderBottom: '2px solid gray', marginTop: '3%', width: '81%' }}></div>
                            <div>
                                <div style={{ padding: '0.5%' }}>
                                    <span style={{ fontSize: '20px' }}>RECENTELY VISITED</span>
                                </div>
                                <div className='col-10 flexItem'>
                                    <div>
                                        <img src={boy1} alt='noboyimg' className='myhome-images' />
                                        <div className='marginTop'>
                                            <button className='viewBtn-myhome'>View Profile</button>
                                        </div>
                                    </div>
                                    <div>
                                        <img src={boytwo} alt='noboyimg' className='myhome-images' />
                                        <div className='marginTop'>
                                            <button className='viewBtnTwo-myhome'>View Profile</button>
                                        </div>
                                    </div>
                                    <div>
                                        <img src={boythre} alt='noboyimg' className='myhome-images' />
                                        <div className='marginTop'>
                                            <button className='viewBtnThree-myhome'>View Profile</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Homefooter />
            </div>
        </div>
    );
}

export default Myhome;
