import React, { useState, useEffect } from 'react';
import Registermodal from './Registermodal';

function StepSeven({
    isStepSevenmodalopen,
    stepSevenmodalclose,
    signupSeven,
    profile_id,
    Income,
}) {
    const [livingWithFamily, setLivingWithFamily] = useState('');
    const [hasSiblings, setHasSiblings] = useState('');
    const [numSisters, setNumSisters] = useState(0);
    const [numBrothers, setNumBrothers] = useState(0);
    const [Fathername, setFathername] = useState('')
    const [FatherOccupation, setFatherOccupation] = useState('')
    const [Mothername, setMothername] = useState('')
    const [MotherOccupation, setMotherOccupation] = useState('')
    const [selectedIncome, setSelectedIncome] = useState('')
    const [Origin, setOrigin] = useState('')
    const [Incomedata, setIncomedata] = useState([])

    useEffect(()=>{
        if(Income !== undefined){
            setIncomedata(Income)
        }
    },[Income])

    const handleRadioChange = (event) => {
        setLivingWithFamily(event.target.value);
    };

    const handleSiblingChange = (event) => {
        setHasSiblings(event.target.value);
    };

    const onsignupSeven = () => {
        if (!livingWithFamily || !Fathername || !FatherOccupation || !Mothername || !MotherOccupation || !Origin || !selectedIncome) {
            alert('Please fill in all fields.');
            return;
        }
    
        if (hasSiblings === 'yes') {
            if (numSisters < 0 || numBrothers < 0) {
                alert('Please enter valid numbers for siblings.');
                return;
            }
        }
        signupSeven({
            live_with_fam: livingWithFamily,
            father_name: Fathername,
            mother_name: Mothername,
            father_occupation: FatherOccupation,
            mother_occupation: MotherOccupation,
            no_of_brothers: numBrothers,
            no_of_sisters: numSisters,
            family_origin:Origin,
            family_income:selectedIncome,
            profile_id,
        })
    }

    const content = (
        <div>
            <div style={{ display: 'flex', gap: '3%' }}>
                <label>Living with family:</label>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <label>
                        <input
                            type="radio"
                            value="yes"
                            checked={livingWithFamily === 'yes'}
                            onChange={handleRadioChange}
                        />
                        Yes
                    </label>
                    <label>
                        <input
                            type="radio"
                            value="no"
                            checked={livingWithFamily === 'no'}
                            onChange={handleRadioChange}
                        />
                        No
                    </label>
                </div>
            </div>
            <div style={{ display: 'flex', gap: '10px', marginTop: '2%' }}>
                <div>
                    <span>Father Name</span>
                    <input
                        placeholder='Enter your Father Name'
                        style={{ width: '100%', padding: '5px', background: 'transparent', border: '1px solid gray', outline: 'none' }}
                        value={Fathername}
                        onChange={(e) => setFathername(e.target.value)}
                    />
                </div>
                <div>
                    <span>Father Occupation</span>
                    <input
                        placeholder='Enter your Father Occupation'
                        style={{ width: '100%', padding: '5px', background: 'transparent', border: '1px solid gray', outline: 'none' }}
                        value={FatherOccupation}
                        onChange={(e) => setFatherOccupation(e.target.value)}
                    />
                </div>
            </div>
            <div style={{ display: 'flex', gap: '10px', marginTop: '2%' }}>
                <div>
                    <span>Mother Name</span>
                    <input
                        placeholder='Enter your Mother Name'
                        style={{ width: '100%', padding: '5px', background: 'transparent', border: '1px solid gray', outline: 'none' }}
                        value={Mothername}
                        onChange={(e) => setMothername(e.target.value)}
                    />
                </div>
                <div>
                    <span>Mother Occupation</span>
                    <input
                        placeholder='Enter your Mother Occupation'
                        style={{ width: '100%', padding: '5px', background: 'transparent', border: '1px solid gray', outline: 'none' }}
                        value={MotherOccupation}
                        onChange={(e) => setMotherOccupation(e.target.value)}
                    />
                </div>
            </div>
            <div>
                <span>Origin</span>
                <input
                    placeholder='Eg : (Kannada, Tamil)'
                    style={{ width: '100%', padding: '5px', background: 'transparent', border: '1px solid gray', outline: 'none' }}
                    value={Origin}
                    onChange={(e) => setOrigin(e.target.value)}
                />
            </div>
            <div>
                <div>
                    <span>Select Your familyIncome (Annual)</span>
                </div>
                <select
                    className="religion-dropdown"
                    value={selectedIncome}
                    onChange={(e) => setSelectedIncome(e.target.value)}
                >
                    <option value="" disabled>Select Your familyIncome</option>
                    {Incomedata.map((income) => (
                        <option key={income.id} value={income.id}>
                            {income.income}
                        </option>
                    ))}
                </select>
            </div>
            <div style={{ marginTop: '2%' }}>
                <label>Do you have siblings?</label>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <label>
                        <input
                            type="radio"
                            value="yes"
                            checked={hasSiblings === 'yes'}
                            onChange={handleSiblingChange}
                        />
                        Yes
                    </label>
                    <label>
                        <input
                            type="radio"
                            value="no"
                            checked={hasSiblings === 'no'}
                            onChange={handleSiblingChange}
                        />
                        No
                    </label>
                </div>
            </div>

            {hasSiblings === 'yes' && (
                <div style={{ display:'flex', justifyContent:'space-between' }}>
                    <div style={{ width:'45%' }}>
                        <span>Number of Sisters</span>
                        <select value={numSisters} onChange={(e) => setNumSisters(e.target.value)} style={{ width: '100%', padding: '5px', background: 'transparent', border: '1px solid gray', outline: 'none' }}>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5+</option>
                        </select>
                    </div>
                    <div style={{ width:'45%' }}>
                        <span>Number of Brothers</span>
                        <select value={numBrothers} onChange={(e) => setNumBrothers(e.target.value)} style={{ width: '100%', padding: '5px', background: 'transparent', border: '1px solid gray', outline: 'none' }}>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5+</option>
                        </select>
                    </div>
                </div>
            )}
            <div className="step-three-button-container">
                <button className="submit-modal-button" onClick={onsignupSeven}>
                    Submit
                </button>
            </div>
        </div>
    );

    return (
        <div>
            <Registermodal
                isOpen={isStepSevenmodalopen}
                onClose={stepSevenmodalclose}
                title="Step Seven Family Details"
                content={content}
            />
        </div>
    );
}

export default StepSeven;
