import React, { useState, useEffect } from 'react'
// import Header from './Header'
import '../../Styles/Myprofile.css'
import logo from '../../assests/logo.png';
import boy1 from '../../assests/boyimg3.png';
import { FaBeer, FaSmokingBan, FaBriefcase, FaGraduationCap } from 'react-icons/fa';
import { GiMeat, GiHumanTarget } from 'react-icons/gi';
import { MdOutlineLanguage } from 'react-icons/md';
import { FaHeart } from 'react-icons/fa';
import Homefooter from '../Homefooter';
import { useNavigate } from 'react-router-dom';

function Myprofile({
    myprofile
}) {
    const [userdetails, setuserdetails] = useState('')
    const navigate = useNavigate()

    console.log('userDetails-->', userdetails)

    useEffect(() => {
        if (myprofile && myprofile.length > 0) {
            // Convert array to object
            setuserdetails(myprofile[0]);
        }
    }, [myprofile]);

    const onclicknavigate = (pathname) => {
        navigate(`/${pathname}`)

    }

    const items = [
        { icon: <GiMeat size={30} />, label: `${userdetails.diet}` },
        { icon: <FaBeer size={30} />, label: `${userdetails.drink}` },
        { icon: <FaSmokingBan size={30} />, label: `${userdetails.smoke}` },
        { icon: <GiHumanTarget size={30} />, label: `${userdetails.bodytype}` },
        { icon: <FaBriefcase size={30} />, label: `${userdetails.occupation}` },
        { icon: <FaGraduationCap size={30} />, label: `${userdetails.edu_detail}`},
        { icon: <MdOutlineLanguage size={30} />, label: `${userdetails.m_tongue}`},
    ];
    const onclickhome = () =>{
        navigate('/myhome')
    }
    return (
        <div className='profile-cont'>
            <div className="container profile-main-container">
                <div className='myprofile-header-content'>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: '20px', cursor:'pointer' }} onClick={onclickhome}>Home</span>
                        <span style={{ marginRight: '20px', cursor:'pointer' }}>Search</span>
                        <span style={{ cursor:'pointer' }}>Membership</span>
                    </div>
                    <div style={{ cursor:'pointer' }} onClick={()=>{onclicknavigate('myhome')}}>
                        <img src={logo} alt='shubamangala' className='profile-header-logo' />
                    </div>
                    <div className='header-button'>
                        <button className='col-10 my-home-register-btn' onClick={() => { onclicknavigate('register') }}>Register</button>
                        <button className='col-10 my-home-login-btn' onClick={() => { onclicknavigate('login') }}>Login</button>
                    </div>
                </div>
            </div>
            <div style={{ borderTop: '2px solid gray' }}></div>
            <div className='profile-content-container'>
                <div className="profile-image-section">
                    <img
                        src={boy1} // Replace with actual image link
                        alt="profile"
                        className="profile-main-image"
                    />
                    <div className="profile-thumbnail-section">
                        {/* Thumbnails */}
                        <img src={boy1} alt="thumbnail" className="thumbnail" />
                        <img src={boy1} alt="thumbnail" className="thumbnail" />
                        <img src={boy1} alt="thumbnail" className="thumbnail" />
                    </div>
                </div>

                <div className="profile-details-section">
                    <div className='profile-name-container'>
                        <div>
                            <span style={{ fontWeight:'bold' }}>{`${userdetails.username} (${userdetails.matri_id})`}</span>
                        </div>
                        <div className='profile-btn-container'>
                            <div>
                                <button style={{ border: 'none', backgroundColor: '#FF6B00', color: 'white', borderRadius: '10px', padding: '10px', height: '90%' }}>Express Intrerst</button>
                            </div>
                            <div>
                                <button style={{ border: 'none', backgroundColor: '#000000', color: 'white', borderRadius: '10px', height: '90%' }}><FaHeart size={20} color='red' /> Shortlist</button>
                            </div>
                        </div>
                    </div>
                    <p><strong>Age:</strong> {userdetails.age}</p>
                    <p><strong>Height:</strong> {userdetails.height}</p>
                    <p><strong>Marital Status:</strong> {userdetails.m_status}</p>
                    <p><strong>Language:</strong> {userdetails.m_tongue}</p>
                    <p><strong>Religion:</strong> {userdetails.religion_name}</p>
                    <p><strong>Cast:</strong> {userdetails.caste_name}</p>
                    <p><strong>Location:</strong> {userdetails.city_name}</p>
                    <p><strong>Culture:</strong> Other</p>
                    <p><strong>Lifestyle:</strong> {userdetails.family_value}</p>
                    <p><strong>Education:</strong> {userdetails.edu_detail}</p>
                    <p><strong>Occupation:</strong>{userdetails.occupation}</p>
                    <p><strong>Contact:</strong> {userdetails.mobile}</p>

                    <div className="profile-action-buttons">
                        <button className="btn btn-primary">Send Email</button>
                        <button className="btn btn-secondary">Message</button>
                        <button className="btn btn-secondary">Call Now</button>
                        <button className="btn btn-secondary">Send SMS</button>
                    </div>

                    <div className="profile-about-section">
                        <h2>About Myself</h2>
                        <p>
                            {userdetails.profile_text}
                        </p>
                    </div>
                </div>
            </div>
            {/* New Content Section Below */}
            <div className="profile-main-container-extra">
                <div>
                    <h3>Life style & Apperance</h3>
                    <div className="lifestyle-container">
                        {items.map((item, index) => (
                            <div key={index} className="lifestyle-item">
                                <span>{item.icon}</span>
                                <span>{item.label}</span>
                            </div>
                        ))}
                    </div>
                </div>
                <div style={{ marginTop: '4%' }}>
                    <div>
                        <h3>More Details</h3>
                        <div>
                            <span>
                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites
                                still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <Homefooter />
        </div>
    )
}

export default Myprofile;
