import { connect } from "react-redux";
import { withRouter } from "../../common/withRouter";
import HomeMain from "./HomeMain";
import {
  religionRequest,
  languageRequest,
  CasteRequest,
  searchRequest,
  FeaturedRequest,
  SubCasteRequest,
  GotraRequest,
  sendOtpRequest,
  RegisterRequest,
  SignupStepOneRequest,
  CountryRequest,
  StateRequest,
  getcityRequest,
  signuptwoRequest,
  getHeightRequest,
  signupthreeRequest,
  getOccupationRequest,
  getEducationRequest,
  signupfourRequest,
  getIncomeRequest,
  signupfiveRequest,
  getRasiRequest,
  getstarRequest,
  signupSixRequest,
  signupSevenRequest,
  signupEightRequest,
  signupNineRequest,
  signupTenRequest
} from "./action";



const mapStateToProps = (state) => {
  const { male, female } = state.search.FeaturedResult;
  // console.log('SignupStepTenResult',state.search.SignupStepTenResult)
    return {
      ...state.search,
      religion: state.search.religionResult.responseData,
      language : state.search.LanguageResult.responseData,
      Registerdata:state.search.Register,
      caste : state.search.casteResult.responseData,
      searchdata : state.search.searchResults.responseData,
      maledata: male ?? '',
      femaledata: female ?? '',
      subcaste:state.search.SubcasteResult.responseData,
      Gotra:state.search.GotraResult.responseData,
      otpresult:state.search.otpResult,
      signupResult:state.search.SignupSteponeResult,
      CountryResult:state.search.CountryResult.responseData,
      statedata:state.search.StateResult.responseData,
      Citydata:state.search.CityResult.responseData,
      signuptwoResult:state.search.SignupSteptwoResult,
      Height:state.search.HeightResult.response,
      signupthreeResult :state.search.SignupStepthreeResult,
      Occupation:state.search.occupationResult.responseData,
      Education:state.search.EducationResult.responseData,
      Income:state.search.IncomeResult.response,
      signupfourResult:state.search.SignupStepfourResult,
      signupfiveResult:state.search.SignupStepfiveResult,
      stardata:state.search.StarResult.response,
      Rasidata:state.search.RasiResult.response,
      signupSixResult:state.search.SignupStepSixResult,
      SignupStepSevenResult:state.search.SignupStepSevenResult,
      SignupEightResult:state.search.SignupStepEightResult,
      SignupStepNineResult:state.search.SignupStepNineResult,
      SignupStepTenResult:state.search.SignupStepTenResult
      // female: state.search.FeaturedResult.female.responseData,
      // male:state.search.FeaturedResult.male.responseData
    };
  };
  
  const mapDispatchToProps = (dispatch) => ({
    religionrequest : () =>(dispatch(religionRequest())),
    languageRequest : ()=>(dispatch(languageRequest())),
    casteRequest : (params)=>(dispatch(CasteRequest(params))),
    searchRequest: (params) =>(dispatch(searchRequest(params))),
    featuredrequest: () =>(dispatch(FeaturedRequest())),
    SubcasteRequest:() =>(dispatch(SubCasteRequest())),
    Gotrarequest : ()=>(dispatch(GotraRequest())),
    otpRequest: (params) =>(dispatch(sendOtpRequest(params))),
    registerdata: (params) =>(dispatch(RegisterRequest(params))),
    signupstepone:(params) =>(dispatch(SignupStepOneRequest(params))),
    country:() =>(dispatch(CountryRequest())),
    getState:(params) =>(dispatch(StateRequest(params))),
    city:(params) =>(dispatch(getcityRequest(params))),
    steptworequest: (params) => (dispatch(signuptwoRequest(params))),
    HeightRequest:() =>(dispatch(getHeightRequest())),
    signupthree:(params) =>(dispatch(signupthreeRequest(params))),
    occupationRequest:()=>(dispatch(getOccupationRequest())),
    Educationdatails:()=> dispatch(getEducationRequest()),
    signupfour:(params) => dispatch(signupfourRequest(params)),
    getincome:() => (dispatch(getIncomeRequest())),
    signupfive:(params)=>(dispatch(signupfiveRequest(params))),
    Rasirequest:() =>(dispatch(getRasiRequest())),
    Starrequest:() =>(dispatch(getstarRequest())),
    signupSix:(params)=> dispatch(signupSixRequest(params)),
    signupSeven:(params)=>(dispatch(signupSevenRequest(params))),
    signupEight:(params)=>(dispatch(signupEightRequest(params))),
    signupNine:(params) =>(dispatch(signupNineRequest(params))),
    signupTen:(params)=> (dispatch(signupTenRequest(params)))
  });
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeMain));