import React, { useState } from 'react';
import Registermodal from './Registermodal';

function StepTen({
    isStepTenmodalopen,
    stepTenmodalclose,
    signupTen,
    profile_id
}) {
    const [aboutYou, setAboutYou] = useState('');
    const [aboutPartner, setAboutPartner] = useState('');

    const handleSubmit = () => {
        if (aboutYou.trim().length < 50) {
            alert('Please enter at least 50 characters about yourself.');
            return;
        }
        if (aboutPartner.trim().length < 50) {
            alert('Please enter at least 50 characters about your partner.');
            return;
        }
        signupTen({
            part_expect:aboutPartner,
            profile_text:aboutYou,
            profile_id
        })
    };

    const content = (
        <div>
            <div>
                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                    <label>About You</label>
                    <textarea
                        value={aboutYou}
                        onChange={(e) => setAboutYou(e.target.value)}
                        placeholder='Enter more than 50 characters about you'
                        rows="4"
                        style={{ width: '100%' }}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                    <label>About Partner</label>
                    <textarea
                        value={aboutPartner}
                        onChange={(e) => setAboutPartner(e.target.value)}
                        placeholder='Enter more than 50 characters about your partner'
                        rows="4"
                        style={{ width: '100%' }}
                    />
                </div>
                <div style={{ marginTop: '2%' }} className="step-three-button-container">
                    <button className="submit-modal-button" onClick={handleSubmit}>
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );

    return (
        <div>
            <Registermodal
                isOpen={isStepTenmodalopen}
                onClose={stepTenmodalclose}
                title="Step Ten"
                content={content}
            />
        </div>
    );
}

export default StepTen;
