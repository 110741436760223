import React, { useState, useEffect } from 'react';
import Registermodal from './Registermodal';
import axios from 'axios'
import Api from '../../../common/Api'

function StepTwo({
    isSteptwomodalopen,
    steptowmodalclose,
    country,
    Countryid,
    handleCountryChange,
    statedata,
    handleStateChange,
    stateid,
    Citydata,
    onsubmitsteptwo,
    city_id,
    onchangecity
}) {
    const [selectedProfile, setSelectedProfile] = useState("");
    const [countrydata, setcountrydata] = useState([]);
    const [stateData, setstateData] = useState([])
    const [CityData, setCityData] = useState([])

    // console.log('stateData',stateData)

    useEffect(() => {
        if (country !== undefined) {
            setcountrydata(country);
        }
        if (statedata !== undefined) {
            setstateData(statedata)
        }
        if (Citydata !== undefined) {
            setCityData(Citydata)
        }
    }, [country, statedata, Citydata]);

    const content = (
        <div className="modal-content">
            <>
                <div>
                    <div>
                        <span>Select Your country</span>
                    </div>
                    <select value={Countryid} onChange={handleCountryChange} className="religion-dropdown" style={{ width: '100%' }}>
                        {countrydata.map((item) => (
                            <option key={item.country_id} value={item.country_id}>
                                {item.country_name}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <div>
                        <span>Select Your State</span>
                    </div>
                    <select onChange={handleStateChange} value={stateid} className="religion-dropdown" style={{ width: '100%' }}>
                        {stateData.map((item) => (
                            <option key={item.state_id} value={item.state_id}>
                                {item.state_name}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <div>
                        <span>Select Your City</span>
                    </div>
                    <select value={city_id} className="religion-dropdown" style={{ width: '100%' }} onChange={onchangecity}>
                        {CityData.map((item) => (
                            <option key={item.city_id} value={item.city_id}>
                                <span style={{ color: 'black' }}>{item.city_name}</span>
                            </option>
                        ))}
                    </select>
                </div>
                <button style={{ marginTop: '2%' }} className="submit-modal-button" onClick={onsubmitsteptwo}>
                    Submit
                </button>
            </>
        </div>
    );

    return (
        <div>
            <Registermodal
                isOpen={isSteptwomodalopen}
                onClose={steptowmodalclose}
                title="Step Two (Location)"
                content={content}
            />
        </div>
    );
}

export default StepTwo;
