import React, { useState, useEffect } from 'react'
import Registermodal from './Registermodal'
import { materialstatus, EmployeIn } from '../../../common/Common';

function StepNine({
    isStepNinemodalopen,
    stepNinemodalclose,
    Height,
    religion,
    casteRequest,
    caste,
    subcaste,
    language,
    country,
    getState,
    statedata,
    city,
    Citydata,
    Education,
    Occupation,
    Income,
    profile_id,
    signupNine
}) {
    const [ageFrom, setAgeFrom] = useState("");
    const [ageTo, setAgeTo] = useState("");
    const [HeightData, setHeightData] = useState([])
    const [selectedHeight, setSelectedHeight] = useState("");
    const [selectedToHeight, setSelectedToHeight] = useState("");
    const [Materialstatus, setMaterialstatus] = useState('')
    const [religionid, setreligionid] = useState('')
    const [caste_id, setcaste_id] = useState('')
    const [castedata, setcastedata] = useState([]);
    const [Religiondata, setReligiondata] = useState([])
    const [subcasedata, setsubcastedata] = useState([])
    const [languagedata, setlanguagedata] = useState([])
    const [subcasteid, setsubcasteid] = useState('')
    const [languageid, setlanguageid] = useState('')
    const [countrydata, setcountrydata] = useState([])
    const [Continue, setContinue] = useState(false)
    const [Countryid, setCountryid] = useState('')
    const [Statedata, setStatedata] = useState([])
    const [stateid, setstateid] = useState('')
    const [cityData, setcityData] = useState([])
    const [city_id, setcity_id] = useState('')
    const [educationdata, seteducationdata] = useState([])
    const [selectedEducation, setSelectedEducation] = useState('')
    const [occupation, setoccupation] = useState([])
    const [selectedOccupation, setSelectedOccupation] = useState('')
    const [incomedata, setincomedata] = useState([])
    const [selectedIncome, setSelectedIncome] = useState('')
    const [selectedEmploymentStatus, setSelectedEmploymentStatus] = useState("");
    const ages = Array.from({ length: 43 }, (_, i) => i + 18);

    useEffect(() => {
        if (Height !== undefined) {
            setHeightData(Height)
        }
        if (caste !== undefined) {
            setcastedata(caste);
        }
        if (religion !== undefined) {
            setReligiondata(religion)
        }
        if (subcaste !== undefined) {
            setsubcastedata(subcaste)
        }
        if (language !== undefined) {
            setlanguagedata(language)
        }
        if (country !== undefined) {
            setcountrydata(country)
        }
        if (statedata !== undefined) {
            setStatedata(statedata)
        }
        if (Citydata !== undefined) {
            setcityData(Citydata)
        }
        if (Education !== undefined) {
            seteducationdata(Education)
        }
        if (Occupation !== undefined) {
            setoccupation(Occupation)
        }
        if (Income !== undefined) {
            setincomedata(Income)
        }
    }, [Height, caste, subcaste, statedata, Citydata, Education, Occupation, Income])

    const handlereligionChange = (e) => {
        setreligionid(e.target.value)
        casteRequest({
            religion_id: Number(e.target.value)
        })
    }

    const handlecasteChange = (e) => {
        setcaste_id(e.target.value)
    }

    const handleSubcasteChange = (e) => {
        setsubcasteid(e.target.value)
    }

    const handlelanguageChange = (e) => {
        setlanguageid(e.target.value)
    }

    const handleCountryChange = (e) => {
        setCountryid(e.target.value)
        getState({
            country_id: e.target.value,
        })
    }

    const onclickcontinue = () => {
        setContinue(true)
    }

    const handleStateChange = (e) => {
        setstateid(e.target.value)
        city({
            state_id: e.target.value,
        });
    }

    const onchangecity = (e) => {
        setcity_id(e.target.value)
    }

    const onclickstepnine = () =>{
        signupNine({
            part_frm_age:ageFrom,
            part_to_age:ageTo,
            part_height_to:selectedToHeight,
            part_height:selectedHeight,
            part_mstatus:Materialstatus,
            part_religion:religionid,
            part_caste:religionid,
            part_subcaste:subcasteid,
            part_mtongue:languageid,
            part_country_living:Countryid,
            part_state:stateid,
            part_city:city_id,
            part_edu:selectedEducation,
            part_occu:selectedOccupation,
            part_emp_in:selectedEmploymentStatus,
            part_income:selectedIncome,
            profile_id
        })
    }

    const content = (
        <div>
            <>
                <div>
                    Select Your Partner Pefereance
                </div>
                {!Continue && (
                    <>
                        <div>
                            <p style={{ fontWeight: 'bold', color: '#368005' }}>Material Status</p>
                            <div className="profile-options">
                                {materialstatus.map((option) => (
                                    <button
                                        key={option}
                                        className={`profile-option ${Materialstatus === option ? "selected" : ""}`}
                                        onClick={() => setMaterialstatus(option)}
                                    >
                                        {option}
                                    </button>
                                ))}
                            </div>
                        </div>
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <div style={{ width: '50%' }}>
                                <div>
                                    <span>Select Religion</span>
                                </div>
                                <select value={religionid} className="religion-dropdown" onChange={handlereligionChange} style={{ width: '100%' }}>
                                    {Religiondata.map((item) => (
                                        <option key={item.religion_id} value={item.religion_id}>
                                            <span style={{ color: 'black' }}>{item.religion_name}</span>
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div style={{ width: '50%' }}>
                                <div>
                                    <span>Select caste</span>
                                </div>
                                <select value={caste_id} onChange={handlecasteChange} className="religion-dropdown" style={{ width: '100%' }}>
                                    {castedata.map((item) => (
                                        <option key={item.caste_id} value={item.caste_id}>
                                            <span style={{ color: 'black' }}>{item.caste_name}</span>
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div>
                            <div>
                                <span>Select Subcaste</span>
                            </div>
                            <select value={subcasteid} onChange={handleSubcasteChange} className="religion-dropdown" style={{ width: '100%' }}>
                                {subcasedata.map((item) => (
                                    <option key={item.sub_caste_id} value={item.sub_caste_id}>
                                        <span style={{ color: 'black' }}>{item.sub_caste_name}</span>
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div style={{ marginTop: '2%' }}>
                            <div>
                                <span>Select Language</span>
                            </div>
                            <select value={languageid} onChange={handlelanguageChange} className="religion-dropdown" style={{ width: '100%' }}>
                                {languagedata.map((item) => (
                                    <option key={item.mtongue_id} value={item.mtongue_id}>
                                        <span style={{ color: 'black' }}>{item.mtongue_name}</span>
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div style={{ display:'flex', gap:'10px' }}>
                            <div style={{ alignSelf: 'center', width: '100%' }}>
                                <div>
                                    <span>From Height</span>
                                </div>
                                <select
                                    className="religion-dropdown"
                                    style={{ width: '100%' }}
                                    value={selectedHeight}
                                    onChange={(e) => setSelectedHeight(e.target.value)}
                                >
                                    {HeightData.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            <span style={{ color: 'black' }}>{item.height}</span>
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div style={{ alignSelf: 'center', width: '100%' }}>
                                <div>
                                    <span>To Height</span>
                                </div>
                                <select
                                    className="religion-dropdown"
                                    style={{ width: '100%' }}
                                    value={selectedToHeight}
                                    onChange={(e) => setSelectedToHeight(e.target.value)}
                                >
                                    {HeightData.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            <span style={{ color: 'black' }}>{item.height}</span>
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="step-three-button-container">
                            <button
                                style={{ marginTop: '2%' }}
                                className="submit-modal-button"
                                onClick={onclickcontinue}
                            >
                                Continue
                            </button>
                        </div>
                    </>
                )}
                {Continue && (
                    <div>
                        <div>
                            <div style={{ width: '100%' }}>
                                <div>
                                    <span> Age</span>
                                </div>
                                <div style={{ display: 'flex', gap: '0.3rem' }}>
                                    <select value={ageFrom} onChange={(e) => setAgeFrom(e.target.value)} style={{ width: '40%' }} className='custom-select-age'>
                                        {ages.map(age => (
                                            <option key={age} value={age}>{age}</option>
                                        ))}
                                    </select>
                                    <div style={{ marginTop: '5%' }}>
                                        <span>To</span>
                                    </div>
                                    <select value={ageTo} onChange={(e) => setAgeTo(e.target.value)} style={{ width: '40%' }} className='custom-select-age'>
                                        {ages.map(age => (
                                            <option key={age} value={age}>{age}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <div style={{ width: '50%' }}>
                                    <div>
                                        <span>Select country</span>
                                    </div>
                                    <select value={Countryid} onChange={handleCountryChange} className="religion-dropdown" style={{ width: '100%' }}>
                                        {countrydata.map((item) => (
                                            <option key={item.country_id} value={item.country_id}>
                                                {item.country_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <div>
                                        <span>Select State</span>
                                    </div>
                                    <select onChange={handleStateChange} value={stateid} className="religion-dropdown" style={{ width: '100%' }}>
                                        {Statedata.map((item) => (
                                            <option key={item.state_id} value={item.state_id}>
                                                {item.state_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <span>Select City</span>
                                </div>
                                <select value={city_id} className="religion-dropdown" style={{ width: '100%' }} onChange={onchangecity}>
                                    {cityData.map((item) => (
                                        <option key={item.city_id} value={item.city_id}>
                                            <span style={{ color: 'black' }}>{item.city_name}</span>
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <div>
                                    <span>Select Education</span>
                                </div>
                                <select
                                    className="religion-dropdown"
                                    value={selectedEducation}
                                    onChange={(e) => setSelectedEducation(e.target.value)}
                                >
                                    {educationdata.map((item) => (
                                        <option key={item.edu_id} value={item.edu_id}>
                                            <span style={{ color: 'black' }}>{item.edu_name}</span>
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <div>
                                    <div>
                                        <span>Select Occupation</span>
                                    </div>
                                    <select
                                        className="religion-dropdown"
                                        value={selectedOccupation}
                                        onChange={(e) => setSelectedOccupation(e.target.value)}
                                    >
                                        {occupation.map((item) => (
                                            <option key={item.ocp_id} value={item.ocp_id}>
                                                <span style={{ color: 'black' }}>{item.ocp_name}</span>
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <div>
                                        <span>Employement status</span>
                                    </div>
                                    <select
                                        className="religion-dropdown"
                                        value={selectedEmploymentStatus}
                                        onChange={(e) => setSelectedEmploymentStatus(e.target.value)}
                                    >
                                        {EmployeIn.map((item, index) => (
                                            <option key={index} value={item}>
                                                <span style={{ color: 'black' }}>{item}</span>
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <span>Select Income (Annual)</span>
                                </div>
                                <select
                                    className="religion-dropdown"
                                    value={selectedIncome}
                                    onChange={(e) => setSelectedIncome(e.target.value)}
                                >
                                    <option value="" disabled>Select Your Income</option>
                                    {incomedata.map((income) => (
                                        <option key={income.id} value={income.id}>
                                            {income.income}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div style={{ marginTop: '2%' }} className="step-three-button-container">
                                <button className="submit-modal-button" onClick={onclickstepnine}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </>
        </div>
    )
    return (
        <div>
            <Registermodal
                isOpen={isStepNinemodalopen}
                onClose={stepNinemodalclose}
                title="Step Nine (Partner Preference)"
                content={content}
            />
        </div>
    )
}

export default StepNine
