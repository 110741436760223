import React, { Component } from 'react';
import Login from '../../Component/Page/Login';

export class Loginclass extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      phonenumber: '',
      otp: '',
    };
  }

  componentDidUpdate(prevProps) {
    const { user, navigate, verifyotpstatus, otpstatus, otpmessage } = this.props;
    if (prevProps.user !== user) {
      if (user.status === "1") {
        const userData = user.responseData
        // console.log('Loginuser', userData)
        localStorage.setItem('user', JSON.stringify(userData))
        localStorage.setItem('profile_id', userData.matri_id)
        localStorage.setItem('currentstep',userData.steps)
        if(userData.steps === 'active'){
          navigate('/myhome');
        } else{
          navigate('/')
        }
      } else if (user.status === "0") {
        alert(user.message);
      }
    }
    if (prevProps.otpstatus !== otpstatus) {
      if (otpstatus === '0') {
        alert(otpmessage || 'Error with OTP.');
      } else {
        alert('OTP Generated Successfully')
      }
    }
    if (prevProps.verifyotpstatus !== verifyotpstatus) {
      const userid = verifyotpstatus.user_id
      localStorage.setItem('userId', userid)
      navigate('/myhome');
    }
  }

  onChangeEmail = (event) => {
    this.setState({ email: event.target.value });
  };

  onChangePassword = (event) => {
    this.setState({ password: event.target.value });
  };

  onChangePhone = (event) => {
    const value = event.target.value
    if (/^\d{0,10}$/.test(value)) {
      this.setState({ phonenumber: value });
    }
  }

  onchangeotp = (event) => {
    const value = event.target.value
    if (/^\d{0,6}$/.test(value)) {
      this.setState({ otp: value });
    }
  }

  onclickotp = () => {
    const { phonenumber } = this.state
    const { otprequest } = this.props
    const phoneRegex = /^\d{10}$/;
    if (phonenumber === '') {
      alert('Please Enter Phone number')
      return;
    }
    if (!phoneRegex.test(phonenumber)) {
      alert('Please Enter Valid phone number');
      return;
    }
    otprequest({
      phonenumber,
    })
  }
  onclickverifyotp = () => {
    const { phonenumber, otp } = this.state
    const { verifyOtp } = this.props
    if (otp === '') {
      alert('Please Enter OTP')
      return;
    }
    verifyOtp({
      otp,
      phonenumber,
    })
  }
  onclick = () => {
    const { email, password } = this.state;
    const { login } = this.props;
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // if (!emailRegex.test(email)) {
    //   alert('Please enter a valid email address.');
    //   return;
    // }
    const trimmedPassword = password.trim();
    if (trimmedPassword.length === 0) {
      alert('Password cannot be empty or just spaces.');
      return;
    }
    if (/\s/.test(trimmedPassword)) {
      alert('Password cannot contain spaces.');
      return;
    }
    const params = {
      email_id: email,
      password,
    };

    login(params); // Dispatch login action
  };

  render() {
    const { email, password, phonenumber, otp } = this.state;
    const { otpstatus } = this.props

    return (
      <div>
        <Login
          email={email}
          password={password}
          onChangeEmail={this.onChangeEmail}
          onChangePassword={this.onChangePassword}
          onClick={this.onclick}
          phonenumber={phonenumber}
          onChangePhone={this.onChangePhone}
          onclickotp={this.onclickotp}
          otpstatus={otpstatus}
          onchangeotp={this.onchangeotp}
          otp={otp}
          onclickverifyotp={this.onclickverifyotp}
        />
      </div>
    );
  }
}

export default Loginclass;
