import React, { useState } from 'react';
import Registermodal from './Registermodal';
import { Diet, smoke, drink } from '../../../common/Common';

function Stepfive({
    isStepfivemodalopen,
    stepfivemodalclose,
    signupfive,
    profile_id,
}) {
    const [selectedDiet, setSelectedDiet] = useState('');
    const [selectedsmoke, setselectedsmoke] = useState('')
    const [selectedDrink, setselectedDrink] = useState('')

    const handleRadioChange = (event) => {
        setSelectedDiet(event.target.value);
    };
    const handlesmokeratio = (e) =>{
        setselectedsmoke(e.target.value)
    }

    const handledrinkratio = (e) =>{
        setselectedDrink(e.target.value)
    }
const onclickstepfive = () =>{
    if (!selectedDiet || !selectedsmoke || !selectedDrink) {
        alert('Please fill in all fields.');
        return;
    }
    signupfive({
        diet:selectedDiet,
        smoke:selectedsmoke,
        drink:selectedDrink,
        profile_id
    })
}
    const content = (
        <div>
            <div>
                <label className='step-three-label'>Diet</label>
                <div style={{ display: 'flex', gap: '5%' }}>
                    {Diet.map((option, index) => (
                        <div key={index}>
                            <input
                                type='radio'
                                id={option}
                                name='diet'
                                value={option}
                                checked={selectedDiet === option}
                                onChange={handleRadioChange}
                            />
                            &nbsp;<label htmlFor={option}>{option}</label> {/* Label for better accessibility */}
                        </div>
                    ))}
                </div>
                <label className='step-three-label'>Smoking</label>
                <div style={{ display: 'flex', gap: '5%' }}>
                    {smoke.map((option, index) => (
                        <div key={index}>
                            <input
                                type='radio'
                                id={option}
                                name='smoke'
                                value={option}
                                checked={selectedsmoke === option}
                                onChange={handlesmokeratio}
                            />
                            &nbsp;<label htmlFor={option}>{option}</label> {/* Label for better accessibility */}
                        </div>
                    ))}
                </div>
                <label className='step-three-label'>Drinking</label>
                <div style={{ display: 'flex', gap: '5%' }}>
                    {drink.map((option, index) => (
                        <div key={index}>
                            <input
                                type='radio'
                                id={option}
                                name='drink'
                                value={option}
                                checked={selectedDrink === option}
                                onChange={handledrinkratio}
                            />
                            &nbsp;<label htmlFor={option}>{option}</label> {/* Label for better accessibility */}
                        </div>
                    ))}
                </div>
                <div className="step-three-button-container">
                    <button className="submit-modal-button" onClick={onclickstepfive}>
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );

    return (
        <div>
            <Registermodal
                isOpen={isStepfivemodalopen}
                onClose={stepfivemodalclose}
                title="Step Five (Life Style)"
                content={content}
            />
        </div>
    );
}

export default Stepfive;
