import './App.css';
import Navigation from './Navigation/Navigation';
import { Provider } from 'react-redux';
import store from './Store/Store';

function App() {
  return (
    <Provider store={store}>
       <Navigation />
     </Provider>
  );
}

export default App;
