import { takeLatest, call, put, all } from 'redux-saga/effects';
import axios from 'axios';
import {
    LANGUAGE_REQUEST,
    RELIGION_REQUEST,
    CASTE_REQUEST,
    PARTNER_SEARCH_REQUEST,
    FEATURED_REQUEST,
    GET_SUB_CASTE_REQUEST,
    GET_GOTRA_REQUEST,
    SEND_OTP_REQUEST,
    REGISTER_REQUEST,
    SIGN_UP_STEP_ONE_REQUEST,
    GET_COUNTRY_REQUEST,
    GET_STATE_REQUEST,
    GET_CITY_REQUEST,
    SIGNUP_STEP_TWO_REQUEST,
    GET_HEIGHT_REQUEST,
    GET_EDUCATION_REQUEST,
    SIGNUP_STEP_THREE_REQUEST,
    GET_OCCUPATION_REQUEST,
    SIGNUP_STEP_FOUR_REQUEST,
    GET_INCOME_REQUEST,
    SIGNUP_STEP_FIVE_REQUEST,
    GET_RASI_REQUEST,
    GET_STAR_REQUEST,
    SIGNUP_STEP_SIX_REQUEST,
    SIGNUP_STEP_SEVEN_REQUEST,
    SIGNUP_STEP_EIGHT_REQUEST,
    SIGNUP_STEP_NINE_REQUEST,
    SIGNUP_STEP_TEN_REQUEST
} from './actionType';
import {
    religionSuccess,
    religionfailure,
    languageSuccess,
    languagefailure,
    CasteSuccess,
    Castefailure,
    searchSuccess,
    searchfailure,
    FeaturedSuccess,
    Featuredfailure,
    SubCasteSuccess,
    SubCastefailure,
    GotraSuccess,
    Gotrafailure,
    sendOtpSuccess,
    sendOtpfailure,
    RegisterSuccess,
    Registerfailure,
    SignupStepOneSuccess,
    SignupStepOnefailure,
    CountrySuccess,
    countryfailure,
    stateSuccess,
    statefailure,
    getcitySuccess,
    getcityFailure,
    signuptwoSuccess,
    signuptwoFailure,
    getHeightSuccess,
    getHeightFailure,
    getEducationSuccess,
    getEducationFailure,
    signupthreeSuccess,
    signupthreeFailure,
    getOccpationSuccess,
    getOccupationFailure,
    signupfourSuccess,
    signupfourFailure,
    getIncomeSuccess,
    getIncomeFailure,
    signupfiveSuccess,
    signupfiveFailure,
    getRasiSuccess,
    getRasiFailure,
    getstarSuccess,
    getstarFailure,
    signupSixSuccess,
    signupSixFailure,
    signupSevenSuccess,
    signupSevenFailure,
    signupEightSuccess,
    signupEightFailure,
    signupNineSuccess,
    signupNineFailure,
    signupTenSuccess,
    signupTenFailure,
} from './action';
import { Api } from '../../common/Api';

function* getBlobAsFile(blobUrl, fileName) {
    const response = yield fetch(blobUrl);
    const blob = yield response.blob();
    return new File([blob], fileName, { type: blob.type });
}

function* religionSaga(action) {
    try {
        const response = yield call(axios.post, `${Api.Apiurl}getReligion.php`, action.payload, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        yield put(religionSuccess(response.data));
    } catch (error) {
        yield put(religionfailure(error.message));
    }
}


export function* watchReligion() {
    yield takeLatest(RELIGION_REQUEST, religionSaga);
}


function* languageSaga(action) {
    try {
        const response = yield call(axios.post, `${Api.Apiurl}getLanguage.php`, action.payload, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        yield put(languageSuccess(response.data));
    } catch (error) {
        yield put(languagefailure(error.message));
    }
}


export function* watchlanguage() {
    yield takeLatest(LANGUAGE_REQUEST, languageSaga);
}

function* CasteSaga(action) {
    const params = {
        religion_id : action.payload.religion_id
    }
    try {
        const response = yield call(axios.post, `${Api.Apiurl}getCaste.php`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        yield put(CasteSuccess(response.data));
    } catch (error) {
        yield put(Castefailure(error.message));
    }
}


export function* watchCaste() {
    yield takeLatest(CASTE_REQUEST, CasteSaga);
}

function* SearchSaga(action) {
    const params = {
        gender : action.payload.gender,
        t3: action.payload.fromage,
        t4: action.payload.toage,
        m_tongue: action.payload.language,
        rel: action.payload.religion,
        caste: action.payload.caste
    }
    try {
        const response = yield call(axios.post, `${Api.Apiurl}search.php`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        yield put(searchSuccess(response.data));
    } catch (error) {
        yield put(searchfailure(error.message));
    }
}


export function* watchsearch() {
    yield takeLatest(PARTNER_SEARCH_REQUEST, SearchSaga);
}

function* FeaturedSaga(action) {
    const maleParams = {
        gender: 'Male',
        limit: '4'
    };
    const femaleParams = {
        gender: 'Female',
        limit: '4'
    };

    try {
        const [maleResponse, femaleResponse] = yield all([
            call(axios.post, `${Api.Apiurl}getFeatured.php`, maleParams, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }),
            call(axios.post, `${Api.Apiurl}getFeatured.php`, femaleParams, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
        ]);
        yield put(FeaturedSuccess({ male: maleResponse.data, female: femaleResponse.data }));
        // console.log('featuredgrrom', maleResponse.data)
    } catch (error) {
        yield put(Featuredfailure(error.message));
    }
}


export function* watchFeatured() {
    yield takeLatest(FEATURED_REQUEST, FeaturedSaga);
}

function* SubcasteSaga(action) {
    try {
        const response = yield call(axios.post, `${Api.Apiurl}getSubcaste.php`, action.payload, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        yield put(SubCasteSuccess(response.data));
    } catch (error) {
        yield put(SubCastefailure(error.message));
    }
}


export function* SubcasteWatch() {
    yield takeLatest(GET_SUB_CASTE_REQUEST, SubcasteSaga);
}

function* GotraSaga(action) {
    try {
        const response = yield call(axios.post, `${Api.Apiurl}getGotra.php`, action.payload, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        yield put(GotraSuccess(response.data));
    } catch (error) {
        yield put(Gotrafailure(error.message));
    }
}

export function* GotraWatch() {
    yield takeLatest(GET_GOTRA_REQUEST, GotraSaga);
}

function* SendOtpSaga(action) {
    const params = {
        mobile : action.payload.mobile,
        email:action.payload.email
    }
    try {
        const response = yield call(axios.post, `${Api.Apiurl}sendOtp.php`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        yield put(sendOtpSuccess(response.data));
    } catch (error) {
        yield put(sendOtpfailure(error.message));
    }
}


export function* watchSendOtp() {
    yield takeLatest(SEND_OTP_REQUEST, SendOtpSaga);
}


function* RegisterSaga(action) {
    // console.log('registersaga', action.payload)
    const params = {
        username : action.payload.username,
        email:action.payload.email,
        mobile: action.payload.phonenumber,
        password: action.payload.password
    }
    try {
        const response = yield call(axios.post, `${Api.Apiurl}register.php`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        yield put(RegisterSuccess(response.data));
        // console.log('RegisterSuccess',(response.data))
    } catch (error) {
        // console.log('RegisterError',(error))
        yield put(Registerfailure(error.message));
    }
}


export function* watchRegister() {
    yield takeLatest(REGISTER_REQUEST, RegisterSaga);
}


function* SignupsteponeSaga(action) {
    // console.log('registersaga', action.payload)
    const params = {
        profileby : action.payload.profileby,
        gender:action.payload.gender,
        m_status: action.payload.m_status,
        religion: action.payload.religion,
        caste:action.payload.caste,
        subcaste:action.payload.subcaste,
        gothra:action.payload.gothra,
        m_tongue:action.payload.m_tongue,
        profile_id:action.payload.profile_id
    }
    try {
        const response = yield call(axios.post, `${Api.Apiurl}signupStep1.php`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        yield put(SignupStepOneSuccess(response.data));
        // console.log('SignupStepOneSuccess',(response.data))
    } catch (error) {
        // console.log('RegisterError',(error))
        yield put(SignupStepOnefailure(error.message));
    }
}


export function* watchSignupstepone() {
    yield takeLatest(SIGN_UP_STEP_ONE_REQUEST, SignupsteponeSaga);
}


function* countrySaga(action) {
    try {
        const response = yield call(axios.post, `${Api.Apiurl}getCountry.php`, action.payload, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        yield put(CountrySuccess(response.data));
    } catch (error) {
        yield put(countryfailure(error.message));
    }
}

export function* watchCountrySaga() {
    yield takeLatest(GET_COUNTRY_REQUEST, countrySaga);
}


function* stateSaga(action) {
    const params = {
        country_id:action.payload.country_id
    }
    try {
        const response = yield call(axios.post, `${Api.Apiurl}getState.php`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        yield put(stateSuccess(response.data));
    } catch (error) {
        yield put(statefailure(error.message));
    }
}

export function* watchStateSaga() {
    yield takeLatest(GET_STATE_REQUEST, stateSaga);
}


function* CitySaga(action) {
    // console.log('citysaga', action.payload)
    const params = {
        state_id : action.payload.state_id
    }
    try {
        const response = yield call(axios.post, `${Api.Apiurl}getCity.php`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        yield put(getcitySuccess(response.data));
    } catch (error) {
        yield put(getcityFailure(error.message));
    }
}

export function* watchCitySaga() {
    yield takeLatest(GET_CITY_REQUEST, CitySaga);
}


function* Signsteptwosaga(action) {
    const params = {
        country_id : action.payload.country_id,
        state_id:action.payload.state_id,
        city:action.payload.city,
        profile_id:action.payload.profile_id,
    }
    try {
        const response = yield call(axios.post, `${Api.Apiurl}signupStep2.php`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        yield put(signuptwoSuccess(response.data));
    } catch (error) {
        yield put(signuptwoFailure(error.message));
    }
}

export function* watchSignupsteptwoSaga() {
    yield takeLatest(SIGNUP_STEP_TWO_REQUEST, Signsteptwosaga);
}



function* HeightSaga(action) {
    // console.log('citysaga', action.payload)
    try {
        const response = yield call(axios.post, `${Api.Apiurl}getHeight.php`, action.payload, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        yield put(getHeightSuccess(response.data));
    } catch (error) {
        yield put(getHeightFailure(error.message));
    }
}

export function* watchHeightSaga() {
    yield takeLatest(GET_HEIGHT_REQUEST, HeightSaga);
}

function* IncomeSaga(action) {
    // console.log('citysaga', action.payload)
    try {
        const response = yield call(axios.post, `${Api.Apiurl}getIncome.php`, action.payload, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        yield put(getIncomeSuccess(response.data));
    } catch (error) {
        yield put(getIncomeFailure(error.message));
    }
}

export function* watchIncomeSaga() {
    yield takeLatest(GET_INCOME_REQUEST, IncomeSaga);
}

function* EducationSaga(action) {
    // console.log('citysaga', action.payload)
    try {
        const response = yield call(axios.post, `${Api.Apiurl}getEducation.php`, action.payload, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        yield put(getEducationSuccess(response.data));
    } catch (error) {
        yield put(getEducationFailure(error.message));
    }
}

export function* watchEducationSaga() {
    yield takeLatest(GET_EDUCATION_REQUEST, EducationSaga);
}

function* Signstepthreesaga(action) {
    // console.log('signupstepthree',action)
    const params = {
        height : action.payload.height,
        weight:action.payload.weight,
        complexion:action.payload.complexion,
        physicalStatus:action.payload.physicalStatus,
        bodytype:action.payload.bodytype,
        profile_id:action.payload.profile_id,
    }
    try {
        const response = yield call(axios.post, `${Api.Apiurl}signupStep3.php`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        yield put(signupthreeSuccess(response.data));
    } catch (error) {
        yield put(signupthreeFailure(error.message));
    }
}

export function* watchSignupstepthreeSaga() {
    yield takeLatest(SIGNUP_STEP_THREE_REQUEST, Signstepthreesaga);
}


function* OccupationSaga(action) {
    // console.log('citysaga', action.payload)
    try {
        const response = yield call(axios.post, `${Api.Apiurl}getOccupation.php`, action.payload, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        yield put(getOccpationSuccess(response.data));
    } catch (error) {
        yield put(getOccupationFailure(error.message));
    }
}

export function* watchOccupationSaga() {
    yield takeLatest(GET_OCCUPATION_REQUEST, OccupationSaga);
}

function* RasiSaga(action) {
    try {
        const response = yield call(axios.post, `${Api.Apiurl}getRasi.php`, action.payload, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        yield put(getRasiSuccess(response.data));
    } catch (error) {
        yield put(getRasiFailure(error.message));
    }
}


export function* watchRasi() {
    yield takeLatest(GET_RASI_REQUEST, RasiSaga);
}

function* StarSaga(action) {
    try {
        const response = yield call(axios.post, `${Api.Apiurl}getStar.php`, action.payload, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        yield put(getstarSuccess(response.data));
    } catch (error) {
        yield put(getstarFailure(error.message));
    }
}


export function* watchStar() {
    yield takeLatest(GET_STAR_REQUEST, StarSaga);
}

function* Signstepfoursaga(action) {
    // console.log('signupstepfour',action)
    const params = {
        edu_detail : action.payload.edu_detail,
        occupation:action.payload.occupation,
        emp_in:action.payload.emp_in,
        income:action.payload.income,
        company_name:action.payload.company_name,
        profile_id:action.payload.profile_id,
    }
    try {
        const response = yield call(axios.post, `${Api.Apiurl}signupStep4.php`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        yield put(signupfourSuccess(response.data));
    } catch (error) {
        yield put(signupfourFailure(error.message));
    }
}

export function* watchSignupstepfourSaga() {
    yield takeLatest(SIGNUP_STEP_FOUR_REQUEST, Signstepfoursaga);
}

function* Signstepfivesaga(action) {
    // console.log('signupstepfour',action)
    const params = {
        diet : action.payload.diet,
        smoke:action.payload.smoke,
        drink:action.payload.drink,
        profile_id:action.payload.profile_id,
    }
    try {
        const response = yield call(axios.post, `${Api.Apiurl}signupStep5.php`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        yield put(signupfiveSuccess(response.data));
    } catch (error) {
        yield put(signupfiveFailure(error.message));
    }
}

export function* watchSignupstepfiveSaga() {
    yield takeLatest(SIGNUP_STEP_FIVE_REQUEST, Signstepfivesaga);
}


function* SignstepSixsaga(action) {
    // console.log('signupstepfour',action)
    const image = action.payload.horoscopeimage
    const horoscope = yield call(getBlobAsFile, image,'horoscope')
    const params = {
        birthdate : action.payload.birthdate,
        birthtime:action.payload.birthtime,
        star:action.payload.star,
        moonsign:action.payload.moonsign,
        profile_id:action.payload.profile_id,
        horoscope,
    }
    try {
        const response = yield call(axios.post, `${Api.Apiurl}signupStep6.php`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        yield put(signupSixSuccess(response.data));
    } catch (error) {
        yield put(signupSixFailure(error.message));
    }
}

export function* watchSignupstepSixSaga() {
    yield takeLatest(SIGNUP_STEP_SIX_REQUEST, SignstepSixsaga);
}


function* SignstepSevensaga(action) {
    const params = {
        live_with_fam : action.payload.live_with_fam,
        father_name:action.payload.father_name,
        mother_name:action.payload.mother_name,
        father_occupation:action.payload.father_occupation,
        mother_occupation:action.payload.mother_occupation,
        no_of_brothers:action.payload.no_of_brothers,
        no_of_sisters:action.payload.no_of_sisters,
        family_income:action.payload.family_income,
        family_origin:action.payload.family_origin,
        profile_id:action.payload.profile_id
    }
    try {
        const response = yield call(axios.post, `${Api.Apiurl}signupStep7.php`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        yield put(signupSevenSuccess(response.data));
    } catch (error) {
        yield put(signupSevenFailure(error.message));
    }
}

export function* watchSignupstepSevenSaga() {
    yield takeLatest(SIGNUP_STEP_SEVEN_REQUEST, SignstepSevensaga);
}


function* SignstepEightsaga(action) {
    // console.log('SignstepEightsaga', action.payload)
    const params = {
        profile_id:action.payload.profile_id,
        hobby:action.payload.hobby
    }
    try {
        // Step9 api Replace to step8 api
        const response = yield call(axios.post, `${Api.Apiurl}signupStep8.php`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        yield put(signupEightSuccess(response.data));
        console.log('signupEightSuccess', response.data)
    } catch (error) {
        yield put(signupEightFailure(error.message));
    }
}

export function* watchSignupstepEightSaga() {
    yield takeLatest(SIGNUP_STEP_EIGHT_REQUEST, SignstepEightsaga);
}



function* SignupstepNineSaga(action) {
    // console.log('Ninesaga', action.payload)
    const params = {
        part_to_age : action.payload.part_to_age,
        part_frm_age:action.payload.part_frm_age,
        part_height_to: action.payload.part_height_to,
        part_height: action.payload.part_height,
        part_mstatus:action.payload.part_mstatus,
        part_religion:action.payload.part_religion,
        part_caste:action.payload.part_caste,
        part_subcaste:action.payload.part_subcaste,
        profile_id:action.payload.profile_id,
        part_mtongue:action.payload.part_mtongue,
        part_country_living:action.payload.part_country_living,
        part_state:action.payload.part_state,
        part_city:action.payload.part_city,
        part_edu:action.payload.part_edu,
        part_occu:action.payload.part_occu,
        part_emp_in:action.payload.part_emp_in,
        part_income:action.payload.part_income,
    }
    try {
        // This step api replace to 9
        const response = yield call(axios.post, `${Api.Apiurl}signupStep9.php`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        yield put(signupNineSuccess(response.data));
        // console.log('SignupStepOneSuccess',(response.data))
    } catch (error) {
        // console.log('RegisterError',(error))
        yield put(signupNineFailure(error.message));
    }
}


export function* watchSignupstepNine() {
    yield takeLatest(SIGNUP_STEP_NINE_REQUEST, SignupstepNineSaga);
}


function* SignstepTensaga(action) {
    // console.log('SignstepEightsaga', action.payload)
    const params = {
        profile_id:action.payload.profile_id,
        part_expect:action.payload.part_expect,
        profile_text:action.payload.profile_text
    }
    try {
        // Step9 api Replace to step8 api
        const response = yield call(axios.post, `${Api.Apiurl}signupStep10.php`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        yield put(signupTenSuccess(response.data));
        // console.log('signupEightSuccess', response.data)
    } catch (error) {
        yield put(signupTenFailure(error.message));
    }
}

export function* watchSignupstepTenSaga() {
    yield takeLatest(SIGNUP_STEP_TEN_REQUEST, SignstepTensaga);
}