import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  OTP_REQUEST,
  OTP_SUCCESS,
  OTP_FAILURE,
  OTP_VERIFY_REQUEST,
  OTP_VERIFY_SUCCESS,
  OTP_VERIFY_FAILURE
} from "./actionType";

export const loginRequest = (credentials) => ({
  type: LOGIN_REQUEST,
  payload: credentials,
});

export const loginSuccess = (data) => ({
  type: LOGIN_SUCCESS,
  payload: data,
});

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

export const OtpRequest = (data) =>({
  type: OTP_REQUEST,
  payload:data
})

export const OtpSuccess = (data)=>({
  type: OTP_SUCCESS,
  payload: data
})

export const Otpfailure = (error)=>({
  type: OTP_FAILURE,
  payload: error
})

export const OtpVerifyRequest = (data)=>({
  type:OTP_VERIFY_REQUEST,
  payload:data,
})
export const OtpVerifySuccess = (data)=>({
  type: OTP_VERIFY_SUCCESS,
  payload: data
})

export const OtpVerifyFailure = (error)=>({
  type: OTP_VERIFY_FAILURE,
  payload: error
})

