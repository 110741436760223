import { connect } from "react-redux";
import Profile from "./Profile";
import { withRouter } from "../../common/withRouter";
import { getprofileRequest } from "./action";

const mapStateToProps = (state) => {
    // console.log('myprofile=======>', state.Profile.myprofile.responseData);
    return {
      ...state.profile,
      myprofile:state.Profile.myprofile.responseData
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        profileRequest :(params) => dispatch(getprofileRequest(params)),
    }
  };

  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));