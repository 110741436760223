export const validateMail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  // export const getLocalDate = ({ dateObj, format } = {}) => {
  //   // const finalOffset = offset || dayjs().utcOffset()
  //   const localDate = dayjs(dateObj).utc().format(format || 'DD/MM/YYYY HH:mm:ss')
  //   return localDate
  // }

  export const setCookie = (obj_to_save, expiryTime) => {
    const keys = Object.keys(obj_to_save)
    let expiry = new Date(Date.now() + 36000000).toUTCString()
    if (expiryTime) {
      expiry = expiryTime
    }
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      const value = obj_to_save[key]
      document.cookie = `${key}=${value};expires=${expiry};path=/;`
    }
  }

  export const deleteCookies = (cookieArr) => {
    cookieArr.map((item) => {
      document.cookie = `${item}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;`
      return null
    })
  }

  export const getCookies = () => {
    let cookies = decodeURIComponent(document.cookie)
    cookies = cookies.split(';')
    const cookie_obj = {}
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].split('=')
      const [key, value] = cookie
      if (key && value) {
        const finalValue = cookie.slice(1, cookie.length).join('=')
        cookie_obj[key.trim()] = finalValue.trim()
      }
    }
    return cookie_obj
  }

  export const setLocalStorage = (item, convert) => {
    Object.keys(item).forEach((key) => {
      localStorage.setItem(key, convert ? JSON.stringify(item[key]) : item[key])
    })
  }
  
  export const getLocalStorage = (key) => {
    return localStorage.getItem(key)
  }
  
  export const deleteLocalStorage = (keyArr, clearAll) => {
    if (clearAll) {
      localStorage.clear()
      return
    }
    keyArr.forEach(item => localStorage.removeItem(item))
  }


  // export const state = [
  //   'Andaman & Nicobar Islands',
  //   'Andhra Pradesh',
  //   'Arunachal Pradesh',
  //   'Assam',
  //   'Bihar',
  //   'Chhattisgarh',
  //   'Daman & Diu',
  //   'Delhi',
  //   'Goa',
  //   'Gujarat',
  //   'Haryana',
  //   'Himachal Pradesh',
  //   'Jammu & Kashmir',
  //   'Jharkhand',
  //   'Karnataka',
  //   'Kerala',
  //   'Lakshadweep', //
  //   'Madhya Pradesh', //
  //   'Maharashtra',
  //   'Manipur',
  //   'Meghalaya',
  //   'Mizoram',
  //   'Nagaland',
  //   'Odisha',
  //   'Puducherry',
  //   'Punjab',
  //   'Rajasthan',
  //   'Sikkim',
  //   'Tamil Nadu',
  //   'Telangana',
  //   'Tripura',
  //   'Uttar Pradesh',
  //   'Uttarakhand',
  //   'West Bengal',
  // ]


  export const BodyType = [
    'Slim',
    'Average',
    'Athletic',
    'Heavy'
  ]

  export const Complexion = [
    'Very Fair',
    'Fair',
    'Wheatish',
    'Wheatish brown',
    'Dark'
  ]

  export const Physicalstatus = [
    'Normal',
    "Physically challenged"
  ]

  export const EmployeIn = [
    'Goverment',
    'Private',
    'Business',
    'Defence',
    'Self Employed'
  ]


  export const Diet = [
    'Vegetarian',
    'Non-Vegetarian',
    'Eggetarian',
  ]

  export const smoke = [
    'No',
    'Yes',
    'Occasionally'
  ]

  export const drink = [
    'No',
    'Yes',
    'Drinks Socially'
  ]

  export const materialstatus = [
    "Never Married",
    "Divorced",
    "Widowed",
    "Awaiting Divorce",
  ];