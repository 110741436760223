import { GET_MY_PROFILE_REQUEST, GET_MY_PROFILE_SUCCESS, GET_MY_PROFILE_FAILURE } from "./actionType";


const initialState = {
    Profileloading: false,
    myprofile: [],
    profileError: null,
};

const Profile = (state = initialState, action) => {
    switch (action.type) {
        case GET_MY_PROFILE_REQUEST:
            return {
                ...state,
                Profileloading: true,
                profileError: null,
            }
        case GET_MY_PROFILE_SUCCESS:
            return {
                ...state,
                Profileloading: false,
                myprofile: action.payload,
                profileError: null,
            }
        case GET_MY_PROFILE_FAILURE:
            return {
                ...state,
                Profileloading: false,
                profileError: action.payload
            }
        default:
            return state;
    }
}

export default Profile