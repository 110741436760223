import React, { useState } from 'react';
import Registermodal from './Registermodal';
import './Stepthree.css';

function StepEight({
    isStepEightmodalopen,
    stepEightmodalclose,
    signupEight,
    profile_id,
}) {
    // Array of hobbies to display
    const hobbies = [
        { name: "Movies", icon: "🎥" },
        { name: "Music", icon: "🎵" },
        { name: "Travelling", icon: "✈️" },
        { name: "Reading", icon: "📚" },
        { name: "Sports", icon: "⚽" },
        { name: "Social media", icon: "📱" },
        { name: "Gaming", icon: "🎮" },
        { name: "Binge-watching", icon: "💻" },
        { name: "Biking", icon: "🚴" },
        { name: "Clubbing", icon: "🎉" },
        { name: "Shopping", icon: "🛍️" },
        { name: "Theater & Events", icon: "🎭" },
        { name: "Anime", icon: "🎌" },
        { name: "Stand ups", icon: "🎤" }
    ];

    const [selectedHobbies, setSelectedHobbies] = useState([]);

    const toggleHobby = (hobby) => {
        setSelectedHobbies((prev) => {
            if (prev.includes(hobby)) {
                return prev.filter((item) => item !== hobby);
            } else if (prev.length < 4) {
                return [...prev, hobby];
            } else {
                alert('You can only select up to 4 hobbies & interests.');
                return prev;
            }
        });
    };

    const onclickcontinue = () => {
        if (selectedHobbies.length < 4) {
            alert('Please select at least 4 hobbies & interests');
            return;
        }
        const hobbiesString = selectedHobbies.join(', ');
        signupEight({
            hobby:hobbiesString,
            profile_id
        })
    };

    const content = (
        <div>
            <>
                <div>
                    <span style={{ fontWeight: 'bold', color: '#f2682a' }}>
                        Please Select Your Hobbies & Interests
                    </span>
                </div>
                <div className="hobbies-container">
                    {hobbies.map((hobby, index) => (
                        <div
                            key={index}
                            className={`hobby-tag ${selectedHobbies.includes(hobby.name) ? 'active' : ''}`}
                            onClick={() => toggleHobby(hobby.name)}
                        >
                            <div>
                                <span className="icon">{hobby.icon}</span>
                            </div>
                            <div>
                                <span>{hobby.name}</span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="step-three-button-container">
                    <button
                        style={{ marginTop: '2%' }}
                        className="submit-modal-button"
                        onClick={onclickcontinue}
                    >
                        Continue
                    </button>
                </div>
            </>
        </div>
    );

    return (
        <div>
            <Registermodal
                isOpen={isStepEightmodalopen}
                onClose={stepEightmodalclose}
                title="Step Eight (Recreation)"
                content={content}
            />
        </div>
    );
}


export default StepEight;
