import {
    PARTNER_SEARCH_REQUEST,
    PARTNER_SEARCH_SUCCESS,
    PARTNER_SEARCH_FAILURE,
    RELIGION_REQUEST,
    RELIGION_SUCCESS,
    RELIGION_FAILURE,
    LANGUAGE_REQUEST,
    LANGUAGE_SUCCESS,
    LANGUAGE_FAILURE,
    CASTE_REQUEST,
    CASTE_SUCCESS,
    CASTE_FAILURE,
    FEATURED_REQUEST,
    FEATURED_SUCCESS,
    FEATURED_FAILURE,
    GET_SUB_CASTE_REQUEST,
    GET_SUB_CASTE_SUCCES,
    GET_SUB_CASTE_FAILURE,
    GET_GOTRA_REQUEST,
    GET_GOTRA_SUCCESS,
    GET_GOTRA_FAILURE,
    SEND_OTP_REQUEST,
    SEND_OTP_SUCCESS,
    SEND_OTP_FAILURE,
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    REGISTER_FAILURE,
    SIGN_UP_STEP_ONE_REQUEST,
    SIGN_UP_STEP_ONE_SUCCESS,
    SIGNUP_STEP_ONE_FAILURE,
    GET_COUNTRY_REQUEST,
    GET_COUNTRY_SUCCESS,
    GET_COUNTRY_FAILURE,
    GET_STATE_REQUEST,
    GET_STATE_SUCCESS,
    GET_STATE_FAILURE,
    GET_CITY_REQUEST,
    GET_CITY_SUCCESS,
    GET_CITY_FAILURE,
    SIGNUP_STEP_TWO_REQUEST,
    SIGNUP_STEP_TWO_SUCCESS,
    SIGNUP_STEP_TWO_FAILURE,
    GET_HEIGHT_REQUEST,
    GET_HEIGHT_SUCCESS,
    GET_HEIGHT_FAILURE,
    GET_EDUCATION_REQUEST,
    GET_EDUCATION_SUCCESS,
    GET_EDUCATION_FAILURE,
    SIGNUP_STEP_THREE_REQUEST,
    SIGNUP_STEP_THREE_SUCCESS,
    SIGNUP_STEP_THREE_FAILURE,
    GET_OCCUPATION_REQUEST,
    GET_OCCUPACTION_SUCCESS,
    GET_OCCUPATION_FAILURE,
    SIGNUP_STEP_FOUR_REQUEST,
    SIGNUP_STEP_FOUR_SUCCESS,
    SIGNUP_STEP_FOUR_FAILURE,
    GET_INCOME_REQUEST,
    GET_INCOME_SUCCESS,
    GET_INCOME_FAILURE,
    SIGNUP_STEP_FIVE_REQUEST,
    SIGNUP_STEP_FIVE_SUCCESS,
    SIGNUP_STEP_FIVE_FAILURE,
    GET_RASI_REQUEST,
    GET_RASI_SUCCESS,
    GET_RASI_FAILURE,
    GET_STAR_REQUEST,
    GET_STAR_SUCCESS,
    GET_STAR_FAILURE,
    SIGNUP_STEP_SIX_REQUEST,
    SIGNUP_STEP_SIX_SUCCESS,
    SIGNUP_STEP_SIX_FAILURE,
    SIGNUP_STEP_SEVEN_REQUEST,
    SIGNUP_STEP_SEVEN_SUCCESS,
    SIGNUP_STEP_SEVEN_FAILURE,
    SIGNUP_STEP_EIGHT_REQUEST,
    SIGNUP_STEP_EIGHT_SUCCESS,
    SIGNUP_STEP_EIGHT_FAILURE,
    SIGNUP_STEP_NINE_REQUEST,
    SIGNUP_STEP_NINE_SUCCESS,
    SIGNUP_STEP_NINE_FAILURE,
    SIGNUP_STEP_TEN_REQUEST,
    SIGNUP_STEP_TEN_SUCCESS,
    SIGNUP_STEP_TEN_FAILURE
} from "./actionType";



export const searchRequest = (data) =>({
    type:PARTNER_SEARCH_REQUEST,
    payload : data
})
export const searchSuccess = (data) => ({
    type: PARTNER_SEARCH_SUCCESS,
    payload : data
})

export const searchfailure = (error) =>({
    type: PARTNER_SEARCH_FAILURE,
    payload: error
})

export const religionRequest = (data) =>({
    type:RELIGION_REQUEST,
    payload : data
})
export const religionSuccess = (data) => ({
    type: RELIGION_SUCCESS,
    payload : data
})

export const religionfailure = (error) =>({
    type: RELIGION_FAILURE,
    payload: error
})

export const languageRequest = (data) =>({
    type:LANGUAGE_REQUEST,
    payload : data
})
export const languageSuccess = (data) => ({
    type: LANGUAGE_SUCCESS,
    payload : data
})

export const languagefailure = (error) =>({
    type: LANGUAGE_FAILURE,
    payload: error
})

export const CasteRequest = (data) =>({
    type:CASTE_REQUEST,
    payload : data
})
export const CasteSuccess = (data) => ({
    type: CASTE_SUCCESS,
    payload : data
})

export const Castefailure = (error) =>({
    type: CASTE_FAILURE,
    payload: error
})

export const FeaturedRequest = (data) =>({
    type:FEATURED_REQUEST,
    payload : data
})
export const FeaturedSuccess = (data) => ({
    type: FEATURED_SUCCESS,
    payload : data
})

export const Featuredfailure = (error) =>({
    type: FEATURED_FAILURE,
    payload: error
})

export const SubCasteRequest = (data) =>({
    type:GET_SUB_CASTE_REQUEST,
    payload : data
})
export const SubCasteSuccess = (data) => ({
    type: GET_SUB_CASTE_SUCCES,
    payload : data
})

export const SubCastefailure = (error) =>({
    type: GET_SUB_CASTE_FAILURE,
    payload: error
})

export const GotraRequest = (data) =>({
    type:GET_GOTRA_REQUEST,
    payload : data
})
export const GotraSuccess = (data) => ({
    type: GET_GOTRA_SUCCESS,
    payload : data
})

export const Gotrafailure = (error) =>({
    type: GET_GOTRA_FAILURE,
    payload: error
})

export const sendOtpRequest = (data) =>({
    type:SEND_OTP_REQUEST,
    payload : data
})
export const sendOtpSuccess = (data) => ({
    type: SEND_OTP_SUCCESS,
    payload : data
})

export const sendOtpfailure = (error) =>({
    type: SEND_OTP_FAILURE,
    payload: error
})

export const RegisterRequest = (data) =>({
    type:REGISTER_REQUEST,
    payload : data
})
export const RegisterSuccess = (data) => ({
    type: REGISTER_SUCCESS,
    payload : data
})

export const Registerfailure = (error) =>({
    type: REGISTER_FAILURE,
    payload: error
})

export const SignupStepOneRequest = (data) =>({
    type:SIGN_UP_STEP_ONE_REQUEST,
    payload : data
})
export const SignupStepOneSuccess = (data) => ({
    type: SIGN_UP_STEP_ONE_SUCCESS,
    payload : data
})

export const SignupStepOnefailure = (error) =>({
    type: SIGNUP_STEP_ONE_FAILURE,
    payload: error
})

export const CountryRequest = (data) =>({
    type:GET_COUNTRY_REQUEST,
    payload : data
})
export const CountrySuccess = (data) => ({
    type: GET_COUNTRY_SUCCESS,
    payload : data
})

export const countryfailure = (error) =>({
    type: GET_COUNTRY_FAILURE,
    payload: error
})


export const StateRequest = (data) =>({
    type:GET_STATE_REQUEST,
    payload : data
})
export const stateSuccess = (data) => ({
    type: GET_STATE_SUCCESS,
    payload : data
})

export const statefailure = (error) =>({
    type: GET_STATE_FAILURE,
    payload: error
})

export const getcityRequest = (data) =>({
    type:GET_CITY_REQUEST,
    payload : data
});

export const getcitySuccess = (data) =>({
    type:GET_CITY_SUCCESS,
    payload:data
});

export const getcityFailure = (error) =>({
    type:GET_CITY_FAILURE,
    payload:error
})

export const signuptwoRequest = (data) =>({
    type:SIGNUP_STEP_TWO_REQUEST,
    payload : data
});

export const signuptwoSuccess = (data) =>({
    type:SIGNUP_STEP_TWO_SUCCESS,
    payload:data
});

export const signuptwoFailure = (error) =>({
    type:SIGNUP_STEP_TWO_FAILURE,
    payload:error
})


export const getHeightRequest = (data) =>({
    type:GET_HEIGHT_REQUEST,
    payload : data
});

export const getHeightSuccess = (data) =>({
    type:GET_HEIGHT_SUCCESS,
    payload:data
});

export const getHeightFailure = (error) =>({
    type:GET_HEIGHT_FAILURE,
    payload:error
})


export const getEducationRequest = (data) =>({
    type:GET_EDUCATION_REQUEST,
    payload : data
});

export const getEducationSuccess = (data) =>({
    type:GET_EDUCATION_SUCCESS,
    payload:data
});

export const getEducationFailure = (error) =>({
    type:GET_EDUCATION_FAILURE,
    payload:error
})

export const signupthreeRequest = (data) =>({
    type:SIGNUP_STEP_THREE_REQUEST,
    payload : data
});

export const signupthreeSuccess = (data) =>({
    type:SIGNUP_STEP_THREE_SUCCESS,
    payload:data
});

export const signupthreeFailure = (error) =>({
    type:SIGNUP_STEP_THREE_FAILURE,
    payload:error
})


export const getOccupationRequest = (data) =>({
    type:GET_OCCUPATION_REQUEST,
    payload : data
});

export const getOccpationSuccess = (data) =>({
    type:GET_OCCUPACTION_SUCCESS,
    payload:data
});

export const getOccupationFailure = (error) =>({
    type:GET_OCCUPATION_FAILURE,
    payload:error
})

export const signupfourRequest = (data) =>({
    type:SIGNUP_STEP_FOUR_REQUEST,
    payload : data
});

export const signupfourSuccess = (data) =>({
    type:SIGNUP_STEP_FOUR_SUCCESS,
    payload:data
});

export const signupfourFailure = (error) =>({
    type:SIGNUP_STEP_FOUR_FAILURE,
    payload:error
})



export const getIncomeRequest = (data) =>({
    type:GET_INCOME_REQUEST,
    payload : data
});

export const getIncomeSuccess = (data) =>({
    type:GET_INCOME_SUCCESS,
    payload:data
});

export const getIncomeFailure = (error) =>({
    type:GET_INCOME_FAILURE,
    payload:error
})

export const signupfiveRequest = (data) =>({
    type:SIGNUP_STEP_FIVE_REQUEST,
    payload : data
});

export const signupfiveSuccess = (data) =>({
    type:SIGNUP_STEP_FIVE_SUCCESS,
    payload:data
});

export const signupfiveFailure = (error) =>({
    type:SIGNUP_STEP_FIVE_FAILURE,
    payload:error
})


export const getRasiRequest = (data) =>({
    type:GET_RASI_REQUEST,
    payload : data
});

export const getRasiSuccess = (data) =>({
    type:GET_RASI_SUCCESS,
    payload:data
});

export const getRasiFailure = (error) =>({
    type:GET_RASI_FAILURE,
    payload:error
})


export const getstarRequest = (data) =>({
    type:GET_STAR_REQUEST,
    payload : data
});

export const getstarSuccess = (data) =>({
    type:GET_STAR_SUCCESS,
    payload:data
});

export const getstarFailure = (error) =>({
    type:GET_STAR_FAILURE,
    payload:error
})


export const signupSixRequest = (data) =>({
    type:SIGNUP_STEP_SIX_REQUEST,
    payload : data
});

export const signupSixSuccess = (data) =>({
    type:SIGNUP_STEP_SIX_SUCCESS,
    payload:data
});

export const signupSixFailure = (error) =>({
    type:SIGNUP_STEP_SIX_FAILURE,
    payload:error
})

export const signupSevenRequest = (data) =>({
    type:SIGNUP_STEP_SEVEN_REQUEST,
    payload : data
});

export const signupSevenSuccess = (data) =>({
    type:SIGNUP_STEP_SEVEN_SUCCESS,
    payload:data
});

export const signupSevenFailure = (error) =>({
    type:SIGNUP_STEP_SEVEN_FAILURE,
    payload:error
})

export const signupEightRequest = (data) =>({
    type:SIGNUP_STEP_EIGHT_REQUEST,
    payload : data
});

export const signupEightSuccess = (data) =>({
    type:SIGNUP_STEP_EIGHT_SUCCESS,
    payload:data
});

export const signupEightFailure = (error) =>({
    type:SIGNUP_STEP_EIGHT_FAILURE,
    payload:error
})

export const signupNineRequest = (data) =>({
    type:SIGNUP_STEP_NINE_REQUEST,
    payload : data
});

export const signupNineSuccess = (data) =>({
    type:SIGNUP_STEP_NINE_SUCCESS,
    payload:data
});

export const signupNineFailure = (error) =>({
    type:SIGNUP_STEP_NINE_FAILURE,
    payload:error
})

export const signupTenRequest = (data) =>({
    type:SIGNUP_STEP_TEN_REQUEST,
    payload : data
});

export const signupTenSuccess = (data) =>({
    type:SIGNUP_STEP_TEN_SUCCESS,
    payload:data
});

export const signupTenFailure = (error) =>({
    type:SIGNUP_STEP_TEN_FAILURE,
    payload:error
})