import React, { Component } from 'react'
import Home from '../../Component/Page/Home';
import Register from '../../Component/Page/Register/Register';
import StepOne from '../../Component/Page/Register/StepOne';
import StepTwo from '../../Component/Page/Register/StepTwo';
import Stepthree from '../../Component/Page/Register/Stepthree';
import StepFour from '../../Component/Page/Register/StepFour';
import Stepfive from '../../Component/Page/Register/Stepfive';
import Stepsix from '../../Component/Page/Register/Stepsix';
import StepSeven from '../../Component/Page/Register/StepSeven';
import StepEight from '../../Component/Page/Register/StepEight';
import StepNine from '../../Component/Page/Register/StepNine';
import StepTen from '../../Component/Page/Register/StepTen';

export class HomeMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        name: '',
        email: '',
        password: '',
        otp: '',
        phonenumber: '',
        mobileotp: '',
      },
      emailSent: false,
      emailVerified: false,
      phoneSent: false,
      phoneVerified: false,
      languageId: 0,
      religionid: 0,
      casteid: 0,
      gender: '',
      modalIsOpen: false,
      steponeactive: false,
      subcasteid: 0,
      Gotra_id: 0,
      profile_id: '',
      isSteptwomodalopen: false,
      isStepthreemodalopen: false,
      isStepfourmodalopen: false,
      isStepfivemodalopen: false,
      isStepsixmodalopen: false,
      isStepSevenmodalopen: false,
      isStepEightmodalopen: false,
      isStepNinemodalopen:false,
      isStepTenmodalopen:false,
      curentstep: 0,
      Countryid: 0,
      stateid: 0,
      city_id: 0
    };
  }

  async componentDidMount() {
    const {
      religionrequest,
      languageRequest,
      featuredrequest,
      SubcasteRequest,
      Gotrarequest,
      country,
      HeightRequest,
      occupationRequest,
      Educationdatails,
      getincome,
      Rasirequest,
      Starrequest,
    } = this.props
    religionrequest()
    languageRequest()
    featuredrequest()
    SubcasteRequest()
    Gotrarequest()
    await this.checkUser()
    country()
    HeightRequest()
    occupationRequest()
    Educationdatails()
    getincome()
    Rasirequest()
    Starrequest()
  }

  async checkUser() {
    try {
      const steps = JSON.parse(await localStorage.getItem('currentstep'));
      const profile_id = await localStorage.getItem('profile_id')
      // console.log('checkuser', steps === 1)
      if (steps !== null) {
        this.setState({ curentstep: JSON.parse(steps) });
        if (steps === 1) {
          this.steptwoactive()
        } else if (steps === 2) {
          this.stepthreeactive()
        } else if (steps === 3) {
          this.stepfouractive()
        } else if (steps === 4) {
          this.stepfiveactive()
        } else if (steps === 5) {
          this.stepsixactive()
        } else if (steps === 6) {
          this.stepSevenactive()
        } else if (steps === 7) {
          this.stepEightactive()
        } else if(steps === 9){
          this.stepNineactive()
        } else if(steps === 10){
          this.stepTenactive()
        }
      }
      if (profile_id) {
        this.setState({
          profile_id: profile_id
        })
      }
    } catch (error) {
      console.log('Error retrieving user id from local storage:', error);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      otpresult,
      Registerdata,
      signupResult,
      signuptwoResult,
      signupthreeResult,
      signupfourResult,
      signupfiveResult,
      signupSixResult,
      SignupStepSevenResult,
      SignupEightResult,
      SignupStepNineResult,
      SignupStepTenResult,
      navigate
    } = this.props;
    if (prevProps.otpresult !== otpresult) {
      if (otpresult.email && otpresult.email.status === '1') {
        alert(otpresult.email.message);
        this.setState({
          emailSent: true
        });
      } else if (otpresult.email) {
        alert(otpresult.email.message);
      }

      if (otpresult.mobile && otpresult.mobile.status === '1') {
        alert(otpresult.mobile.message);
        this.setState({
          phoneSent: true
        });
      } else if (otpresult.mobile) {
        alert(otpresult.mobile.message);
      }
    }
    if (prevProps.Registerdata !== Registerdata) {
        if (Registerdata.status === '1') {
          alert(Registerdata.message);
          localStorage.setItem('profile_id', Registerdata.matri_id)
          this.setState({
            steponeactive: true,
            profile_id: Registerdata.matri_id
          })
          this.onclosemodal()
        } else {
          alert(Registerdata.message)
        }
    }
    if (prevProps.signupResult !== signupResult) {
        if (signupResult.status === '1') {
          alert(signupResult.message)
          this.setState({
            isSteptwomodalopen: true
          })
          this.modalclose()
        } else {
          alert(signupResult.message)
        }
    }
    if (prevProps.signuptwoResult !== signuptwoResult) {
        if (signuptwoResult.status === '1') {
          alert(signuptwoResult.message)
          this.steptowmodalclose()
          this.stepthreeactive()
        } else {
          alert(signuptwoResult.message)
        }
    }
    if (prevProps.signupthreeResult !== signupthreeResult) {
        if (signupthreeResult.status === '1') {
          alert(signupthreeResult.message)
          this.stepfouractive()
          this.stepthreemodalclose()
          // this.stepthreeactive()
        } else {
          alert(signupthreeResult.message)
        }
    }
    if (prevProps.signupfourResult !== signupfourResult) {
        if (signupfourResult.status === '1') {
          alert(signupfourResult.message)
          this.stepfourmodalclose()
          this.stepfiveactive()
        } else {
          alert(signupfourResult.message)
        }
    }
    if (prevProps.signupfiveResult !== signupfiveResult) {
        if (signupfiveResult.status === '1') {
          alert(signupfiveResult.message)
          this.stepfivemodalclose()
          this.stepsixactive()
        } else {
          alert(signupfiveResult.message)
        }
    }
    if (prevProps.signupSixResult !== signupSixResult) {
        if (signupSixResult.status === '1') {
          alert(signupSixResult.message)
          this.stepsixmodalclose()
          this.stepSevenactive()
        } else {
          alert(signupSixResult.message)
        }
    }
    if (prevProps.SignupStepSevenResult !== SignupStepSevenResult) {
        if (SignupStepSevenResult.status === '1') {
          alert(SignupStepSevenResult.message)
          this.stepSevenmodalclose()
          this.stepEightactive()
        } else {
          alert(SignupStepSevenResult.message)
        }
    }
    if (prevProps.SignupEightResult !== SignupEightResult) {
        if (SignupEightResult.status === '1') {
          alert(SignupEightResult.message)
          this.stepEightmodalclose()
          this.stepNineactive()
        } else {
          alert(SignupEightResult.message)
        }
    }
    if (prevProps.SignupStepNineResult !== SignupStepNineResult) {
      if (SignupStepNineResult.status === '1') {
        alert(SignupStepNineResult.message)
        this.stepNinemodalclose()
        this.stepTenactive()
      } else {
        alert(SignupStepNineResult.message)
      }
  }
  if (prevProps.SignupStepTenResult !== SignupStepTenResult) {
    if (SignupStepTenResult.status === '1') {
      alert(SignupStepTenResult.message)
      navigate('/myhome')
    } else {
      alert(SignupStepTenResult.message)
    }
}
  }

  handlegenderchange = (e) => {
    this.setState({
      gender: e.target.value
    })
  }
  handleReligionChange = (e) => {
    const { casteRequest } = this.props
    this.setState({
      religionid: e.target.value
    })
    casteRequest({
      religion_id: Number(e.target.value)
    })
  };

  handlelanguageChange = (e) => {
    this.setState({
      languageId: e.target.value,
    })
  }

  handlecasteChange = (e) => {
    this.setState({
      casteid: e.target.value,
    })
  }

  handleSubcasteChange = (e) => {
    this.setState({
      subcasteid: e.target.value,
    })
  }

  handleCountryChange = (e) => {
    const { getState } = this.props
    this.setState({
      Countryid: e.target.value,
    })
    getState({
      country_id: e.target.value,
    })
  }

  handleGotraChange = (e) => {
    this.setState({
      Gotra_id: e.target.value,
    });
  }

  onclickmodal = () => {
    this.setState({ modalIsOpen: true })
  }

  onclosemodal = () => {
    this.setState({ modalIsOpen: false })
  }
  modalclose = () => {
    this.setState({
      steponeactive: false
    })
  }
  steptowmodalclose = () => {
    this.setState({
      isSteptwomodalopen: false
    })
  }

  stepthreemodalclose = () => {
    this.setState({
      isStepthreemodalopen: false,
    })
  }
  stepfourmodalclose = () => {
    this.setState({
      isStepfourmodalopen: false,
    })
  }

  stepfivemodalclose = () => {
    this.setState({
      isStepfivemodalopen: false,
    })
  }
  stepsixmodalclose = () => {
    this.setState({
      isStepsixmodalopen: false,
    })
  }

  stepSevenmodalclose = () => {
    this.setState({
      isStepSevenmodalopen: false,
    })
  }

  stepEightmodalclose = () => {
    this.setState({
      isStepEightmodalopen: false,
    })
  }

  stepNinemodalclose = () => {
    this.setState({
      isStepNinemodalopen: false,
    })
  }
  stepTenmodalclose = () => {
    this.setState({
      isStepTenmodalopen: false,
    })
  }

  handlereligionChange = (e) => {
    console.log(e.target.value)
    const { casteRequest } = this.props
    this.setState({
      religionid: e.target.value
    })
    casteRequest({
      religion_id: Number(e.target.value)
    })
  }
  handleChange = (e) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  };

  handleSendEmail = () => {
    this.setState({ emailSent: true });
  };

  handleRegistersubmit = () => {
    const { registerdata } = this.props
    const { formData } = this.state
    const { name, email, phonenumber, password } = formData;
    const trimmedPassword = password.trim();
    if (!name || !email || !phonenumber || !trimmedPassword) {
      alert('Please fill in all fields.');
      return;
    }
    if (phonenumber.length !== 10) {
      alert('Phone number must be exactly 10 digits.');
      return;
    }
    if (/\s/.test(trimmedPassword)) {
      alert('Password cannot contain spaces.');
      return;
    }
    registerdata({
      username: formData.name,
      email: formData.email,
      phonenumber: formData.phonenumber,
      password: formData.password,
    })
  }

  steptwoactive = () => {
    this.setState({
      isSteptwomodalopen: true
    })
  }
  stepthreeactive = () => {
    this.setState({
      isStepthreemodalopen: true
    })
  }
  stepfouractive = () => {
    this.setState({
      isStepfourmodalopen: true
    })
  }

  stepfiveactive = () => {
    this.setState({
      isStepfivemodalopen: true
    })
  }

  stepsixactive = () => {
    this.setState({
      isStepsixmodalopen: true
    })
  }
  stepSevenactive = () => {
    this.setState({
      isStepSevenmodalopen: true
    })
  }
  stepEightactive = () => {
    this.setState({
      isStepEightmodalopen: true
    })
  }

  stepNineactive = () => {
    this.setState({
      isStepNinemodalopen: true
    })
  }
  stepTenactive = () => {
    this.setState({
      isStepTenmodalopen: true
    })
  }

  handleStateChange = (e) => {
    const { city } = this.props;
    const selectedStateId = e.target.value;
    this.setState({
      stateid: selectedStateId,
    });
    city({
      state_id: selectedStateId,
    });
  }
  onchangecity = (e) => {
    this.setState({
      city_id: e.target.value
    })
  }

  onsubmitsteptwo = () => {
    const { steptworequest } = this.props
    const { Countryid, stateid, city_id, profile_id } = this.state

    steptworequest({
      country_id: Countryid,
      state_id: stateid,
      city: city_id,
      profile_id: profile_id
    })
  }


  render() {
    const {
      religion,
      language,
      caste,
      searchRequest,
      searchdata,
      maledata,
      femaledata,
      subcaste,
      Gotra,
      otpRequest,
      otpresult,
      signupstepone,
      CountryResult,
      statedata,
      Citydata,
      Height,
      signupthree,
      Occupation,
      Education,
      signupfour,
      Income,
      signupfive,
      stardata,
      Rasidata,
      signupSix,
      signupSeven,
      signupEight,
      casteRequest,
      getState,
      city,
      signupNine,
      signupTen
    } = this.props
    const {
      languageId,
      religionid,
      casteid,
      gender,
      modalIsOpen,
      steponeactive,
      formData,
      emailSent,
      phoneSent,
      subcasteid,
      Gotra_id,
      profile_id,
      isSteptwomodalopen,
      Countryid,
      stateid,
      city_id,
      isStepthreemodalopen,
      isStepfourmodalopen,
      isStepfivemodalopen,
      isStepsixmodalopen,
      isStepSevenmodalopen,
      isStepEightmodalopen,
      isStepNinemodalopen,
      isStepTenmodalopen
    } = this.state

    return (
      <div>
        <Home
          religion={religion}
          language={language}
          handleReligionChange={this.handleReligionChange}
          handlelanguageChange={this.handlelanguageChange}
          handlecasteChange={this.handlecasteChange}
          languageId={languageId}
          religionid={religionid}
          caste={caste}
          casteid={casteid}
          handlegenderchange={this.handlegenderchange}
          searchRequest={searchRequest}
          gender={gender}
          searchdata={searchdata}
          maledata={maledata}
          femaledata={femaledata}
          onclickmodal={this.onclickmodal}
          onclosemodal={this.onclosemodal}
        />
        <Register
          modalopen={modalIsOpen}
          closemodal={this.onclosemodal}
          handleSubmit={this.handleSubmit}
          handleChangeinput={this.handleChange}
          formData={formData}
          emailSent={emailSent}
          phoneSent={phoneSent}
          handleSendEmail={this.handleSendEmail}
          otpRequest={otpRequest}
          handleRegistersubmit={this.handleRegistersubmit}
          otpresult={otpresult}
        />
        <StepOne
          stepconelosemodal={this.modalclose}
          steponemodalopen={steponeactive}
          religion={religion}
          handlereligionChange={this.handlereligionChange}
          religionid={religionid}
          caste={caste}
          subcaste={subcaste}
          language={language}
          Gotra={Gotra}
          signupstepone={signupstepone}
          handlecasteChange={this.handlecasteChange}
          caste_id={casteid}
          subcasteid={subcasteid}
          handleSubcasteChange={this.handleSubcasteChange}
          handleGotraChange={this.handleGotraChange}
          Gotra_id={Gotra_id}
          languageId={languageId}
          profile_id={profile_id}
          handlelanguageChange={this.handlelanguageChange}
        />
        <StepTwo
          isSteptwomodalopen={isSteptwomodalopen}
          steptowmodalclose={this.steptowmodalclose}
          country={CountryResult}
          handleCountryChange={this.handleCountryChange}
          Countryid={Countryid}
          statedata={statedata}
          handleStateChange={this.handleStateChange}
          stateid={stateid}
          Citydata={Citydata}
          onsubmitsteptwo={this.onsubmitsteptwo}
          onchangecity={this.onchangecity}
          city_id={city_id}
        />
        <Stepthree
          isStepthreemodalopen={isStepthreemodalopen}
          stepthreemodalclose={this.stepthreemodalclose}
          Height={Height}
          profile_id={profile_id}
          signupthree={signupthree}
        />
        <StepFour
          isStepfourmodalopen={isStepfourmodalopen}
          stepfourmodalclose={this.stepfourmodalclose}
          Occupation={Occupation}
          Education={Education}
          signupfour={signupfour}
          profile_id={profile_id}
          Income={Income}
        />
        <Stepfive
          isStepfivemodalopen={isStepfivemodalopen}
          stepfivemodalclose={this.stepfivemodalclose}
          signupfive={signupfive}
          profile_id={profile_id}
        />
        <Stepsix
          isStepsixmodalopen={isStepsixmodalopen}
          stepsixmodalclose={this.stepsixmodalclose}
          Rasidata={Rasidata}
          stardata={stardata}
          signupSix={signupSix}
          profile_id={profile_id}
        />
        <StepSeven
          isStepSevenmodalopen={isStepSevenmodalopen}
          stepSevenmodalclose={this.stepSevenmodalclose}
          signupSeven={signupSeven}
          profile_id={profile_id}
          Income={Income}
        />
        <StepEight
          isStepEightmodalopen={isStepEightmodalopen}
          stepEightmodalclose={this.stepEightmodalclose}
          profile_id={profile_id}
          signupEight={signupEight}
        />
        <StepNine 
          isStepNinemodalopen={isStepNinemodalopen}
          stepNinemodalclose={this.stepNinemodalclose}
          Height={Height}
          religion={religion}
          casteRequest={casteRequest}
          caste={caste}
          subcaste={subcaste}
          language={language}
          country={CountryResult}
          getState={getState}
          statedata={statedata}
          city={city}
          Citydata={Citydata}
          Education={Education}
          Occupation={Occupation}
          Income={Income}
          profile_id={profile_id}
          signupNine={signupNine}
        />
        <StepTen 
          isStepTenmodalopen={isStepTenmodalopen}
          stepTenmodalclose={this.stepTenmodalclose}
          signupTen={signupTen}
          profile_id={profile_id}
        />
      </div>
    )
  }
}

export default HomeMain
