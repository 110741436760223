import React, { useState } from 'react';
import '../../Styles/Login.css';
import logo from '../../assests/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
// import Navbar from './Navbar';

function Login({
    email,
    password,
    onChangeEmail,
    onChangePassword,
    onClick,
    onChangePhone,
    phonenumber,
    onclickotp,
    otpstatus,
    onchangeotp,
    otp,
    onclickverifyotp
}) {
    const [loginWithOTP, setLoginWithOTP] = useState(false);
    const [showPasswordInput, setShowPasswordInput] = useState(true);
    // const [showOTPInput, setShowOTPInput] = useState(false);
    // const [otpSent, setOTPSent] = useState(false);
    const navigate = useNavigate()

    const handleLoginWithOTP = () => {
        setLoginWithOTP(true);
        setShowPasswordInput(false);
    };

    const handleSendOTP = () => {
        // setShowOTPInput(true);
        onclickotp()
    };

    const onclicklogo = (parameter) => {
        navigate(`/${parameter}`)
    }
    const onclicklogin = () => {
        window.location.reload();
    }

    return (
        <div>
            <div className='login-container-img' onClick={() => { onclicklogo('') }} style={{ cursor: 'pointer' }}>
                <img src={logo} alt='no logo' className='login-image' />
            </div>
            <div className='login-container'>
                <div className='login-img-before'></div>
                <div className='login-box'>
                    <h2 style={{ color: '#f2682a', fontWeight: 'bold' }}>LOGIN</h2>
                    <p style={{ color: '#368005', fontWeight: 'bold' }}>And search your life partner</p>
                    {loginWithOTP ? (
                        <div className='input-box'>
                            <FontAwesomeIcon icon={faEnvelope} className='icon' />
                            <input
                                type='text'
                                placeholder='Enter your phone number'
                                value={phonenumber}
                                onChange={onChangePhone}
                            />
                        </div>
                    ) : (
                        <div className='input-box'>
                            <FontAwesomeIcon icon={faEnvelope} className='icon' />
                            <input
                                type='text'
                                placeholder='Enter your email id or Profile id'
                                value={email}
                                onChange={onChangeEmail}
                            />
                        </div>
                    )}
                    {showPasswordInput && (
                        <div className='input-box'>
                            <FontAwesomeIcon icon={faLock} className='icon' />
                            <input
                                type='password'
                                placeholder='Enter your password'
                                value={password}
                                onChange={onChangePassword}
                            />
                        </div>
                    )}
                    {otpstatus === '1' && (
                        <div className='input-box'>
                            <FontAwesomeIcon icon={faLock} className='icon' />
                            <input
                                type='number'
                                placeholder='Enter your OTP'
                                value={otp}
                                onChange={onchangeotp}
                            />
                        </div>
                    )}
                    <div className='options'>
                        <div>
                            <input type='checkbox' id='keep-logged-in' />
                            <label htmlFor='keep-logged-in'> Keep me logged in</label>
                        </div>
                        <a href='/forgetpassword'>Forgot Password?</a>
                    </div>
                    {!loginWithOTP && (
                        <button className='login-btn' onClick={onClick}>
                            Login Now
                        </button>
                    )}
                    <div className='alternative-login'>
                        {showPasswordInput ? (
                            <>
                                <p>OR</p>
                                <button className='otp-login-btn' onClick={handleLoginWithOTP}>
                                    Login with OTP
                                </button>
                            </>
                        ) : (
                            <>
                                {otpstatus !== '1' && (
                                    <button className='otp-login-btn' onClick={handleSendOTP}>
                                        Send OTP
                                    </button>
                                )}
                                {otpstatus === '1' && (
                                    <button className='otp-login-btn' onClick={onclickverifyotp}>
                                        Submit
                                    </button>
                                )}
                                <button className='password-login-btn' onClick={onclicklogin}>
                                    Login with password
                                </button>
                            </>
                        )}
                        <a className='verification-link' href='/register'>
                            You don't have account?
                            &nbsp;<span>Register</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
