import {
    PARTNER_SEARCH_REQUEST,
    PARTNER_SEARCH_SUCCESS,
    PARTNER_SEARCH_FAILURE,
    RELIGION_REQUEST,
    RELIGION_SUCCESS,
    RELIGION_FAILURE,
    LANGUAGE_REQUEST,
    LANGUAGE_SUCCESS,
    LANGUAGE_FAILURE,
    CASTE_REQUEST,
    CASTE_SUCCESS,
    CASTE_FAILURE,
    FEATURED_REQUEST,
    FEATURED_SUCCESS,
    FEATURED_FAILURE,
    GET_SUB_CASTE_REQUEST,
    GET_SUB_CASTE_SUCCES,
    GET_SUB_CASTE_FAILURE,
    GET_GOTRA_REQUEST,
    GET_GOTRA_SUCCESS,
    GET_GOTRA_FAILURE,
    SEND_OTP_REQUEST,
    SEND_OTP_SUCCESS,
    SEND_OTP_FAILURE,
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    REGISTER_FAILURE,
    SIGN_UP_STEP_ONE_REQUEST,
    SIGN_UP_STEP_ONE_SUCCESS,
    SIGNUP_STEP_ONE_FAILURE,
    GET_COUNTRY_REQUEST,
    GET_COUNTRY_SUCCESS,
    GET_COUNTRY_FAILURE,
    GET_STATE_REQUEST,
    GET_STATE_SUCCESS,
    GET_STATE_FAILURE,
    GET_CITY_REQUEST,
    GET_CITY_SUCCESS,
    GET_CITY_FAILURE,
    SIGNUP_STEP_TWO_REQUEST,
    SIGNUP_STEP_TWO_SUCCESS,
    SIGNUP_STEP_TWO_FAILURE,
    GET_HEIGHT_REQUEST,
    GET_HEIGHT_SUCCESS,
    GET_HEIGHT_FAILURE,
    GET_EDUCATION_REQUEST,
    GET_EDUCATION_SUCCESS,
    GET_EDUCATION_FAILURE,
    SIGNUP_STEP_THREE_REQUEST,
    SIGNUP_STEP_THREE_SUCCESS,
    SIGNUP_STEP_THREE_FAILURE,
    GET_OCCUPATION_REQUEST,
    GET_OCCUPACTION_SUCCESS,
    GET_OCCUPATION_FAILURE,
    SIGNUP_STEP_FOUR_REQUEST,
    SIGNUP_STEP_FOUR_SUCCESS,
    SIGNUP_STEP_FOUR_FAILURE,
    GET_INCOME_REQUEST,
    GET_INCOME_SUCCESS,
    GET_INCOME_FAILURE,
    SIGNUP_STEP_FIVE_REQUEST,
    SIGNUP_STEP_FIVE_SUCCESS,
    SIGNUP_STEP_FIVE_FAILURE,
    GET_STAR_REQUEST,
    GET_STAR_SUCCESS,
    GET_STAR_FAILURE,
    GET_RASI_REQUEST,
    GET_RASI_SUCCESS,
    GET_RASI_FAILURE,
    SIGNUP_STEP_SIX_REQUEST,
    SIGNUP_STEP_SIX_FAILURE,
    SIGNUP_STEP_SIX_SUCCESS,
    SIGNUP_STEP_SEVEN_REQUEST,
    SIGNUP_STEP_SEVEN_SUCCESS,
    SIGNUP_STEP_SEVEN_FAILURE,
    SIGNUP_STEP_EIGHT_REQUEST,
    SIGNUP_STEP_EIGHT_SUCCESS,
    SIGNUP_STEP_EIGHT_FAILURE,
    SIGNUP_STEP_NINE_REQUEST,
    SIGNUP_STEP_NINE_SUCCESS,
    SIGNUP_STEP_NINE_FAILURE,
    SIGNUP_STEP_TEN_REQUEST,
    SIGNUP_STEP_TEN_SUCCESS,
    SIGNUP_STEP_TEN_FAILURE
} from "./actionType";


const initialState = {
    loading: false,
    searchResults: [],
    error: null,

    isreligionloading: false,
    religionResult: [],
    isreligionerror: null,

    isLanguageLoading: false,
    LanguageResult: [],
    isLanguageError: [],


    isCasteLoading: false,
    casteResult: [],
    iscasteError: [],

    isfeaturedloading: false,
    FeaturedResult: [],
    isfeaturedError: null,

    isSubCasteLoading: false,
    SubcasteResult: [],
    isSubcasteError: [],

    isGotraLoading: false,
    GotraResult: [],
    isGotraError: [],

    isSendotpLoading: false,
    otpResult: [],
    isSendotpError: null,

    isRegisterLoading: false,
    Register: [],
    isRegisterError: null,


    isSignupsteponeLoading: false,
    SignupSteponeResult: [],
    isSignupSteponeError: null,

    isCountryLoading: false,
    CountryResult: [],
    CountryError: null,


    isStateLoading: false,
    StateResult: [],
    StateError: null,

    isCityLoading: false,
    CityResult: [],
    CityError: null,

    isSignupsteptwoLoading: false,
    SignupSteptwoResult: [],
    isSignupSteptwoError: null,

    isHeightLoading: false,
    HeightResult: [],
    HeightError: null,

    isEducationLoading: false,
    EducationResult: [],
    EducationError: null,

    isSignupstepthreeLoading: false,
    SignupStepthreeResult: [],
    isSignupStepthreeError: null,

    isOccupationLoading: false,
    occupationResult: [],
    occupationError: null,

    isSignupstepfourLoading: false,
    SignupStepfourResult: [],
    isSignupStepfourError: null,

    isIncomeLoading: false,
    IncomeResult: [],
    IncomeError: null,

    isSignupstepfiveLoading: false,
    SignupStepfiveResult: [],
    isSignupStepfiveError: null,

    isStarLoading: false,
    StarResult: [],
    StarError: null,

    isRasiLoading: false,
    RasiResult: [],
    RasiError: null,

    isSignupstepSixLoading: false,
    SignupStepSixResult: [],
    isSignupStepSixError: null,

    isSignupstepSevenLoading: false,
    SignupStepSevenResult: [],
    isSignupStepSevenError: null,

    isSignupstepEightLoading: false,
    SignupStepEightResult: [],
    isSignupStepEightError: null,

    isSignupstepNineLoading: false,
    SignupStepNineResult: [],
    isSignupStepNineError: null,

    isSignupstepTenLoading: false,
    SignupStepTenResult: [],
    isSignupStepTenError: null,
};

export const search = (state = initialState, action) => {
    // console.log('action.type---->', action.type === CASTE_REQUEST)
    switch (action.type) {
        case PARTNER_SEARCH_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case PARTNER_SEARCH_SUCCESS:
            return {
                ...state,
                loading: false,
                searchResults: action.payload,
                error: null
            }
        case PARTNER_SEARCH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case RELIGION_REQUEST:
            return {
                ...state,
                isreligionloading: true,
                error: null,
            }
        case RELIGION_SUCCESS:
            return {
                ...state,
                isreligionloading: false,
                religionResult: action.payload,
                error: null
            }
        case RELIGION_FAILURE:
            return {
                ...state,
                isreligionloading: false,
                isreligionerror: action.payload
            }
        case LANGUAGE_REQUEST:
            return {
                ...state,
                isLanguageLoading: true,
            }
        case LANGUAGE_SUCCESS:
            return {
                ...state,
                isLanguageLoading: false,
                LanguageResult: action.payload,
                isLanguageError: null
            }
        case LANGUAGE_FAILURE:
            return {
                ...state,
                isLanguageLoading: false,
                isLanguageError: action.payload
            }
        case CASTE_REQUEST:
            return {
                ...state,
                isCasteLoading: true,
            }
        case CASTE_SUCCESS:
            return {
                ...state,
                isCasteLoading: false,
                casteResult: action.payload,
                iscasteError: null
            }
        case CASTE_FAILURE:
            return {
                ...state,
                isCasteLoading: false,
                iscasteError: action.payload,
            }
        case FEATURED_REQUEST:
            return {
                ...state,
                isfeaturedloading: true,
            }
        case FEATURED_SUCCESS:
            return {
                ...state,
                isfeaturedloading: false,
                FeaturedResult: action.payload,
                isfeaturedError: null
            }
        case FEATURED_FAILURE:
            return {
                ...state,
                isfeaturedloading: false,
                isfeaturedError: action.payload
            }
        case GET_SUB_CASTE_REQUEST:
            return {
                ...state,
                isSubCasteLoading: true,
            }
        case GET_SUB_CASTE_SUCCES:
            return {
                ...state,
                isSubCasteLoading: false,
                SubcasteResult: action.payload,
                isSubcasteError: null
            }
        case GET_SUB_CASTE_FAILURE:
            return {
                ...state,
                isSubCasteLoading: false,
                isSubcasteError: action.payload,
            }
        case GET_GOTRA_REQUEST:
            return {
                ...state,
                isGotraLoading: true,
            }
        case GET_GOTRA_SUCCESS:
            return {
                ...state,
                isGotraLoading: false,
                GotraResult: action.payload,
                isGotraError: null
            }
        case GET_GOTRA_FAILURE:
            return {
                ...state,
                isGotraLoading: false,
                isGotraError: action.payload,
            }
        case SEND_OTP_REQUEST:
            return {
                ...state,
                isSendotpLoading: true,
            }
        case SEND_OTP_SUCCESS:
            return {
                ...state,
                isSendotpLoading: false,
                otpResult: action.payload,
                isSendotpError: null
            }
        case SEND_OTP_FAILURE:
            return {
                ...state,
                isSendotpLoading: false,
                isSendotpError: action.payload,
            }
        case REGISTER_REQUEST:
            return {
                ...state,
                isRegisterLoading: true,
            }
        case REGISTER_SUCCESS:
            return {
                ...state,
                isRegisterLoading: false,
                Register: action.payload,
                isRegisterError: null
            }
        case REGISTER_FAILURE:
            return {
                ...state,
                isRegisterLoading: false,
                isRegisterError: action.payload,
            }
        case SIGN_UP_STEP_ONE_REQUEST:
            return {
                ...state,
                isSignupsteponeLoading: true,
            }
        case SIGN_UP_STEP_ONE_SUCCESS:
            return {
                ...state,
                isSignupsteponeLoading: false,
                SignupSteponeResult: action.payload,
                isSignupSteponeError: null
            }
        case SIGNUP_STEP_ONE_FAILURE:
            return {
                ...state,
                isSignupsteponeLoading: false,
                isSignupSteponeError: action.payload,
            }
        case GET_COUNTRY_REQUEST:
            return {
                ...state,
                isCountryLoading: true,
            }
        case GET_COUNTRY_SUCCESS:
            return {
                ...state,
                isCountryLoading: false,
                CountryResult: action.payload,
                isSignupSteponeError: null
            }
        case GET_COUNTRY_FAILURE:
            return {
                ...state,
                isCountryLoading: false,
                CountryError: action.payload,
            }
        case GET_STATE_REQUEST:
            return {
                ...state,
                isSateLoading: true,
            }
        case GET_STATE_SUCCESS:
            return {
                ...state,
                isSateLoading: false,
                StateResult: action.payload,
                StateError: null
            }
        case GET_STATE_FAILURE:
            return {
                ...state,
                isSateLoading: false,
                StateError: action.payload,
            }
        case GET_CITY_REQUEST:
            return {
                ...state,
                isCityLoading: true,
            }
        case GET_CITY_SUCCESS:
            return {
                ...state,
                isCityLoading: false,
                CityResult: action.payload,
                CityError: null
            }
        case GET_CITY_FAILURE:
            return {
                ...state,
                isCityLoading: false,
                CityError: action.payload,
            }

        case SIGNUP_STEP_TWO_REQUEST:
            return {
                ...state,
                isSignupsteptwoLoading: true,
            }
        case SIGNUP_STEP_TWO_SUCCESS:
            return {
                ...state,
                isSignupsteptwoLoading: false,
                SignupSteptwoResult: action.payload,
                isSignupSteptwoError: null
            }
        case SIGNUP_STEP_TWO_FAILURE:
            return {
                ...state,
                isSignupsteptwoLoading: false,
                isSignupSteptwoError: action.payload,
            }

        case GET_HEIGHT_REQUEST:
            return {
                ...state,
                isHeightLoading: true,
            }
        case GET_HEIGHT_SUCCESS:
            return {
                ...state,
                isHeightLoading: false,
                HeightResult: action.payload,
                HeightError: null
            }
        case GET_HEIGHT_FAILURE:
            return {
                ...state,
                isHeightLoading: false,
                HeightError: action.payload,
            }
        case GET_EDUCATION_REQUEST:
            return {
                ...state,
                isEducationLoading: true,
            }
        case GET_EDUCATION_SUCCESS:
            return {
                ...state,
                isEducationLoading: false,
                EducationResult: action.payload,
                EducationError: null
            }
        case GET_EDUCATION_FAILURE:
            return {
                ...state,
                isEducationLoading: false,
                EducationError: action.payload,
            }
        case SIGNUP_STEP_THREE_REQUEST:
            return {
                ...state,
                isSignupstepthreeLoading: true,
            }
        case SIGNUP_STEP_THREE_SUCCESS:
            return {
                ...state,
                isSignupsteptwoLoading: false,
                SignupStepthreeResult: action.payload,
                isSignupStepthreeError: null
            }
        case SIGNUP_STEP_THREE_FAILURE:
            return {
                ...state,
                isSignupstepthreeLoading: false,
                isSignupStepthreeError: action.payload,
            }
        case GET_OCCUPATION_REQUEST:
            return {
                ...state,
                isOccupationLoading: true,
            }
        case GET_OCCUPACTION_SUCCESS:
            return {
                ...state,
                isOccupationLoading: false,
                occupationResult: action.payload,
                occupationError: null
            }
        case GET_OCCUPATION_FAILURE:
            return {
                ...state,
                isOccupationLoading: false,
                occupationError: action.payload,
            }

        case SIGNUP_STEP_FOUR_REQUEST:
            return {
                ...state,
                isSignupstepfourLoading: true,
            }
        case SIGNUP_STEP_FOUR_SUCCESS:
            return {
                ...state,
                isSignupstepfourLoading: false,
                SignupStepfourResult: action.payload,
                isSignupStepfourError: null
            }
        case SIGNUP_STEP_FOUR_FAILURE:
            return {
                ...state,
                isSignupstepfourLoading: false,
                isSignupStepfourError: action.payload,
            }
            case GET_INCOME_REQUEST:
            return {
                ...state,
                isIncomeLoading: true,
            }
        case GET_INCOME_SUCCESS:
            return {
                ...state,
                isIncomeLoading: false,
                IncomeResult: action.payload,
                IncomeError: null
            }
        case GET_INCOME_FAILURE:
            return {
                ...state,
                isIncomeLoading: false,
                IncomeError: action.payload,
            }
            case SIGNUP_STEP_FIVE_REQUEST:
            return {
                ...state,
                isSignupstepfiveLoading: true,
            }
        case SIGNUP_STEP_FIVE_SUCCESS:
            return {
                ...state,
                isSignupstepfiveLoading: false,
                SignupStepfiveResult: action.payload,
                isSignupStepfiveError: null
            }
        case SIGNUP_STEP_FIVE_FAILURE:
            return {
                ...state,
                isSignupstepfiveLoading: false,
                isSignupStepfiveError: action.payload,
            }

            case GET_STAR_REQUEST:
            return {
                ...state,
                isStarLoading: true,
            }
        case GET_STAR_SUCCESS:
            return {
                ...state,
                isStarLoading: false,
                StarResult: action.payload,
                StarError: null
            }
        case GET_STAR_FAILURE:
            return {
                ...state,
                isStarLoading: false,
                StarError: action.payload,
            }
            case GET_RASI_REQUEST:
            return {
                ...state,
                isRasiLoading: true,
            }
        case GET_RASI_SUCCESS:
            return {
                ...state,
                isRasiLoading: false,
                RasiResult: action.payload,
                RasiError: null
            }
        case GET_RASI_FAILURE:
            return {
                ...state,
                isRasiLoading: false,
                RasiError: action.payload,
            }
            case SIGNUP_STEP_SIX_REQUEST:
            return {
                ...state,
                isSignupstepSixLoading: true,
            }
        case SIGNUP_STEP_SIX_SUCCESS:
            return {
                ...state,
                isSignupstepSixLoading: false,
                SignupStepSixResult: action.payload,
                isSignupStepSixError: null
            }
        case SIGNUP_STEP_SIX_FAILURE:
            return {
                ...state,
                isSignupstepSixLoading: false,
                isSignupStepSixError: action.payload,
            }
            case SIGNUP_STEP_SEVEN_REQUEST:
            return {
                ...state,
                isSignupstepSevenLoading: true,
            }
        case SIGNUP_STEP_SEVEN_SUCCESS:
            return {
                ...state,
                isSignupstepSevenLoading: false,
                SignupStepSevenResult: action.payload,
                isSignupStepSevenError: null
            }
        case SIGNUP_STEP_SEVEN_FAILURE:
            return {
                ...state,
                isSignupstepSevenLoading: false,
                isSignupStepSevenError: action.payload,
            }
            case SIGNUP_STEP_EIGHT_REQUEST:
            return {
                ...state,
                isSignupstepEightLoading: true,
            }
        case SIGNUP_STEP_EIGHT_SUCCESS:
            return {
                ...state,
                isSignupstepEightLoading: false,
                SignupStepEightResult: action.payload,
                isSignupStepEightError: null
            }
        case SIGNUP_STEP_EIGHT_FAILURE:
            return {
                ...state,
                isSignupstepEightLoading: false,
                isSignupStepEightError: action.payload,
            }
            case SIGNUP_STEP_NINE_REQUEST:
            return {
                ...state,
                isSignupstepNineLoading: true,
            }
        case SIGNUP_STEP_NINE_SUCCESS:
            return {
                ...state,
                isSignupstepNineLoading: false,
                SignupStepNineResult: action.payload,
                isSignupStepNineError: null
            }
        case SIGNUP_STEP_NINE_FAILURE:
            return {
                ...state,
                isSignupstepNineLoading: false,
                isSignupStepNineError: action.payload,
            }
            case SIGNUP_STEP_TEN_REQUEST:
            return {
                ...state,
                isSignupstepTenLoading: true,
            }
        case SIGNUP_STEP_TEN_SUCCESS:
            return {
                ...state,
                isSignupstepTenLoading: false,
                SignupStepTenResult: action.payload,
                isSignupStepTenError: null
            }
        case SIGNUP_STEP_TEN_FAILURE:
            return {
                ...state,
                isSignupstepTenLoading: false,
                isSignupStepTenError: action.payload,
            }
        default:
            return state
    }
}
