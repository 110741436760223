import React, { Component } from 'react'
import Myprofile from '../../Component/Page/Myprofile'

export class Profile extends Component {

    async componentDidMount() {
        const { profileRequest } = this.props
        const matri_id = await localStorage.getItem('matri_id')
        profileRequest({
            matri_id,
        })
    }
    render() {
        const {myprofile} = this.props
        return (
            <div>
                <Myprofile 
                    myprofile={myprofile}
                />
            </div>
        )
    }
}

export default Profile
