export const PARTNER_SEARCH_REQUEST = 'PARTNER_SEARCH_REQUEST';
export const PARTNER_SEARCH_SUCCESS = 'PARTNER_SEARCH_SUCCESS';
export const PARTNER_SEARCH_FAILURE = 'PARTNER_SEARCH_FAILURE';

export const RELIGION_REQUEST = 'RELIGION_REQUEST';
export const RELIGION_SUCCESS = 'RELIGION_SUCCESS';
export const RELIGION_FAILURE = 'RELIGION_FAILURE';

export const LANGUAGE_REQUEST = 'LANGUAGE_REQUEST';
export const LANGUAGE_SUCCESS = 'LANGUAGE_SUCCESS';
export const LANGUAGE_FAILURE = 'LANGUAGE_FAILURE';

export const CASTE_REQUEST = 'CASTE_REQUEST';
export const CASTE_SUCCESS = 'CASTE_SUCCESS';
export const CASTE_FAILURE = 'CASTE_FAILURE';


export const FEATURED_REQUEST = 'FEATURED_REQUEST';
export const FEATURED_SUCCESS = 'FEATURED_SUCCESS';
export const FEATURED_FAILURE = 'FEATURED_FAILURE';


export const GET_SUB_CASTE_REQUEST = 'GET_SUB_CASTE_REQUEST';
export const GET_SUB_CASTE_SUCCES = 'GET_SUB_CASTE_SUCCES';
export const GET_SUB_CASTE_FAILURE = 'GET_SUB_CASTE_FAILURE';


export const GET_GOTRA_REQUEST = 'GET_GOTRA_REQUEST';
export const GET_GOTRA_SUCCESS = 'GET_GOTRA_SUCCESS';
export const GET_GOTRA_FAILURE = 'GET_GOTRA_FAILURE';

export const SEND_OTP_REQUEST = 'SEND_OTP_REQUEST';
export const SEND_OTP_SUCCESS = 'SEND_OTP_SUCCESS';
export const SEND_OTP_FAILURE = 'SEND_OTP_FAILURE';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';


export const GET_COUNTRY_REQUEST = 'GET_COUNTRY_REQUEST';
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS';
export const GET_COUNTRY_FAILURE = 'GET_COUNTRY_FAILURE';

export const GET_STATE_REQUEST = 'GET_STATE_REQUEST';
export const GET_STATE_SUCCESS = 'GET_STATE_SUCCESS';
export const GET_STATE_FAILURE = 'GET_STATE_FAILURE';

export const GET_CITY_REQUEST = 'GET_CITY_REQUEST';
export const GET_CITY_SUCCESS = 'GET_CITY_SUCCESS';
export const GET_CITY_FAILURE = 'GET_CITY_FAILURE';

export const GET_HEIGHT_REQUEST = 'GET_HEIGHT_REQUEST';
export const GET_HEIGHT_SUCCESS = 'GET_HEIGHT_SUCCESS';
export const GET_HEIGHT_FAILURE = 'GET_HEIGHT_FAILURE';

export const GET_EDUCATION_REQUEST = 'GET_EDUCATION_REQUEST';
export const GET_EDUCATION_SUCCESS = 'GET_EDUCATION_SUCCESS';
export const GET_EDUCATION_FAILURE = 'GET_EDUCATION_FAILURE';

export const GET_OCCUPATION_REQUEST = 'GET_OCCUPATION_REQUEST';
export const GET_OCCUPACTION_SUCCESS = 'GET_OCCUPACTION_SUCCESS';
export const GET_OCCUPATION_FAILURE = 'GET_OCCUPATION_FAILURE';

export const GET_INCOME_REQUEST = 'GET_INCOME_REQUEST';
export const GET_INCOME_SUCCESS = 'GET_INCOME_SUCCESS';
export const GET_INCOME_FAILURE = 'GET_INCOME_FAILURE';

export const GET_RASI_REQUEST = 'GET_RASI_REQUEST';
export const GET_RASI_SUCCESS = 'GET_RASI_SUCCESS';
export const GET_RASI_FAILURE = 'GET_RASI_FAILURE';

export const GET_STAR_REQUEST = 'GET_STAR_REQUEST';
export const GET_STAR_SUCCESS = 'GET_STAR_SUCCESS';
export const GET_STAR_FAILURE = 'GET_STAR_FAILURE';

// Register steps
export const SIGN_UP_STEP_ONE_REQUEST = 'SIGN_UP_STEP_ONE_REQUEST';
export const SIGN_UP_STEP_ONE_SUCCESS = 'SIGN_UP_STEP_ONE_SUCCESS';
export const SIGNUP_STEP_ONE_FAILURE = 'SIGNUP_STEP_ONE_FAILURE';

//Register step two

export const SIGNUP_STEP_TWO_REQUEST = 'SIGNUP_STEP_TWO_REQUEST';
export const SIGNUP_STEP_TWO_SUCCESS = 'SIGNUP_STEP_TWO_SUCCESS';
export const SIGNUP_STEP_TWO_FAILURE = 'SIGNUP_STEP_TWO_FAILURE';

//Register step Three

export const SIGNUP_STEP_THREE_REQUEST = 'SIGNUP_STEP_THREE_REQUEST';
export const SIGNUP_STEP_THREE_SUCCESS = 'SIGNUP_STEP_THREE_SUCCESS';
export const SIGNUP_STEP_THREE_FAILURE = 'SIGNUP_STEP_THREE_FAILURE';

//Register step four

export const SIGNUP_STEP_FOUR_REQUEST = 'SIGNUP_STEP_FOUR_REQUEST';
export const SIGNUP_STEP_FOUR_SUCCESS = 'SIGNUP_STEP_FOUR_SUCCESS';
export const SIGNUP_STEP_FOUR_FAILURE = 'SIGNUP_STEP_FOUR_FAILURE';

// Register step five

export const SIGNUP_STEP_FIVE_REQUEST = 'SIGNUP_STEP_FIVE_REQUEST';
export const SIGNUP_STEP_FIVE_SUCCESS = 'SIGNUP_STEP_FIVE_SUCCESS';
export const SIGNUP_STEP_FIVE_FAILURE = 'SIGNUP_STEP_FIVE_FAILURE';


// Register step six

export const SIGNUP_STEP_SIX_REQUEST = 'SIGNUP_STEP_SIX_REQUEST';
export const SIGNUP_STEP_SIX_SUCCESS = 'SIGNUP_STEP_SIX_SUCCESS';
export const SIGNUP_STEP_SIX_FAILURE = 'SIGNUP_STEP_SIX_FAILURE';

// Register Step Seven

export const SIGNUP_STEP_SEVEN_REQUEST = 'SIGNUP_STEP_SEVEN_REQUEST';
export const SIGNUP_STEP_SEVEN_SUCCESS = 'SIGNUP_STEP_SEVEN_SUCCESS';
export const SIGNUP_STEP_SEVEN_FAILURE = 'SIGNUP_STEP_SEVEN_FAILURE';


// Register Step Eight

export const SIGNUP_STEP_EIGHT_REQUEST = 'SIGNUP_STEP_EIGHT_REQUEST';
export const SIGNUP_STEP_EIGHT_SUCCESS = 'SIGNUP_STEP_EIGHT_SUCCESS';
export const SIGNUP_STEP_EIGHT_FAILURE = 'SIGNUP_STEP_EIGHT_FAILURE';

// Register Step Nine

export const SIGNUP_STEP_NINE_REQUEST = 'SIGNUP_STEP_NINE_REQUEST';
export const SIGNUP_STEP_NINE_SUCCESS = 'SIGNUP_STEP_NINE_SUCCESS';
export const SIGNUP_STEP_NINE_FAILURE = 'SIGNUP_STEP_NINE_FAILURE';

// Register Step Ten

export const SIGNUP_STEP_TEN_REQUEST = 'SIGNUP_STEP_TEN_REQUEST';
export const SIGNUP_STEP_TEN_SUCCESS = 'SIGNUP_STEP_TEN_SUCCESS';
export const SIGNUP_STEP_TEN_FAILURE = 'SIGNUP_STEP_TEN_FAILURE';