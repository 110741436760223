import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import HomeRedux from '../Container/Home/Home-Redux'
// import Login from '../Component/Page/Login'
import LoginRedux from '../Container/Login/Login-redux'
// import RegisterRedux from '../Container/Register/Register-redux'
import Myhome from '../Component/Page/Myhome'
// import Myprofile from '../Component/Page/Myprofile'
import ForgetPassword from '../Component/Page/Forgotpassword/ForgetPassword'
import ProfileRedux from '../Container/Profile/Profile-redux'
import Aboutus from '../Component/Page/Static-pages/Aboutus'
import PrivacyPolicy from '../Component/Page/Static-pages/PrivacyPolicy'
import Terms from '../Component/Page/Static-pages/Terms'

function Navigation() {
  return (
    <div>
      <Router>
        <Routes>
            <Route path='' element={<HomeRedux />}/>
            <Route path='login' element={<LoginRedux />}/>
            {/* <Route path='register' element={<RegisterRedux />}/> */}
            <Route path='myhome' element={<Myhome />} />
            <Route path='myprofile' element={<ProfileRedux />} />
            <Route path='forgetpassword' element={<ForgetPassword />} />
            <Route path='aboutus' element={<Aboutus />} />
            <Route path='privacypolicy' element={<PrivacyPolicy />} />
            <Route path='terms' element={<Terms />} />
        </Routes>
      </Router>
    </div>
  )
}

export default Navigation
