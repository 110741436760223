import React, { useEffect, useState } from 'react';
import '../Styles/Homeview1.css';
import Girlimage from '../assests/Girlimg1.png';
import frontimg from '../assests/bottomImg.png';
// import boy1 from '../assests/boyimg3.png';
// import boytwo from '../assests/boyimg1.png';
// import boythre from '../assests/boyimg2.png';
import { useNavigate } from 'react-router-dom';
import ModalComponent from '../common/Modal';

function HomeViewone({
  male,
}) {
  const [modalType, setModalType] = useState(null);
  const [modalvisible, setModalVisible] = useState(false)
  const [maledata, setmaledata] = useState([])

  const navigation = useNavigate();
  useEffect(() => {
    if (male.responseData !== undefined) {
      setmaledata(male.responseData)
    }
  }, [male])

  const handleregister = () => {
    navigation('/register');
  };

  const handlelogin = () =>{
    navigation('/login')
  }

  const onprofileclick = () => {
    setModalType('loginSignup')
    setModalVisible(true)
  }
  return (
    <div className='home-view-container'>
      <div className='col-12 headerSection'>
        <div className='col-5 imageWrapper'>
          <img src={Girlimage} alt='nogirlimg' className='girlImage' />
          <img src={frontimg} alt='frontimg' className='frontImage' />
        </div>
        <div className='col-12 textSection'>
          <h3 className='title'>Welcome to ShubhaMangala Matrimony</h3>
          <p className='description'>
          where love meets tradition! Our platform connects individuals seeking meaningful relationships and lifelong partnerships. With personalized matchmaking, a user-friendly interface, and a diverse community, we make finding your perfect match easier than ever. Join us today and take the first step towards your happily ever after!
          </p>
          <div className='buttonGroup'>
            <button className='knowMoreBtn'>Know More</button>
            <button className='signUpBtn' onClick={handleregister}>Sign Up Now</button>
          </div>
          <div className='contactSection'>
            <div className='contactText'>
              <span>Have any questions?<br />Contact us!</span>
            </div>
            <div className='contactNumber'>
              <span>+91 86601 36546</span>
            </div>
          </div>
        </div>
      </div>
      <div className='featuredGroomSection'>
        <div className='flexContainer'>
          <div className='groom-content' style={{ alignSelf: 'center' }}>
            <p className='sectionTitle'>FEATURED GROOM</p>
            <div>
              <span className='sectionDescription'>
              Meet our featured grooms who are successful, charming, and ready to start their journey towards love!
              </span>
              <div>
              <a style={{ color: '#ff6b00', cursor:'pointer', borderBottom:'2px solid #ff6b00', backgroundColor:'white'}} onClick={onprofileclick}>View all</a>
              </div>
            </div>
          </div>
          <div className='flexItem'>
            {maledata.map((data, index) => (
              <div key={index}>
                <img src={data.user_profile_picture} alt='noboyimg' className='images' />
                <div className='marginTop'>
                  <button onClick={onprofileclick} className='viewBtn'>View Profile</button>
                </div>
              </div>
            ))}
            {/* <div>
              <img src={boytwo} alt='noboyimg' className='images' width='90%' />
              <div className='marginTop'>
                <button className='viewBtnTwo'>View Profile</button>
              </div>
            </div>
            <div>
              <img src={boythre} alt='noboyimg' className='images' width='90%' />
              <div className='marginTop'>
                <button className='viewBtnThree'>View Profile</button>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <ModalComponent
        isOpen={modalvisible}
        onRequestClose={() => setModalVisible(false)}
        type={modalType}
        // onCancel={oncancel}
        title="Please Login or Register"
        onSignup={handleregister}
        onLogin={handlelogin}
      />
    </div>
  );
}

export default HomeViewone;
