import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

const ModalComponent = ({
    isOpen,
    onRequestClose,
    type,
    onConfirm,
    onCancel,
    onLogin,
    onSignup,
    title,
    message
}) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const renderContent = () => {
        switch (type) {
            case 'error':
                return <p style={{ color: 'red' }}>{message}</p>;
            case 'success':
                return <p style={{ color: 'green' }}>{message}</p>;
            case 'confirmation':
                return (
                    <div style={styles.confirmationContent}>
                        <p>{message}</p>
                        <div style={styles.buttonContainer}>
                            <button style={styles.button} onClick={onConfirm}>Confirm</button>
                            <button style={styles.button} onClick={onCancel}>Cancel</button>
                        </div>
                    </div>
                );
            case 'loginSignup':
                return (
                    <div style={styles.loginSignupContent}>
                        <h2>{title}</h2>
                        <div style={styles.closeButton} onClick={onRequestClose}>
                            &times;
                        </div>
                        <div style={styles.buttonContainer}>
                            <button style={styles.button} onClick={onLogin}>Login</button>
                            <button style={styles.buttonsignup} onClick={onSignup}>Register</button>
                        </div>
                    </div>
                );
            case 'showmodel':
                return (
                    <div style={styles.showModelContent}>
                        <p>{message}</p>
                        <div style={styles.showModelButtonContainer}>
                            <button style={styles.modalbutton} onClick={onCancel}>OK</button>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={isMobile ? mobileModalStyles : modalStyles}
            ariaHideApp={false}
        >
            <div style={styles.modalContent}>
                {renderContent()}
            </div>
        </Modal>
    );
};

const modalStyles = {
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        padding: '20px',
        borderRadius: '10px',
        width: '40%',
        maxWidth: '500px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
        position: 'relative',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
    },
};

const mobileModalStyles = {
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        padding: '20px',
        borderRadius: '10px',
        width: '90%',
        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
        position: 'relative',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
    },
    buttonContainer: {
        display: 'flex',
        marginTop: '1rem',
        flexDirection: 'column',
        width: '100%',
    },
};

const styles = {
    modalContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    closeButton: {
        position: 'absolute',
        top: '5px',
        right: '10px',
        cursor: 'pointer',
        fontSize: '25px',
        color: 'black',
    },
    buttonContainer: {
        display: 'flex',
        marginTop: '1rem',
        justifyContent: 'space-between',
        gap: '2rem',
        width: '100%',
    },
    button: {
        backgroundColor: '#368005',
        width: '100%',
        padding: 10,
        border: 'none',
        color: 'white',
        fontWeight: 'bold',
        cursor: 'pointer',
        textAlign: 'center',
        borderRadius: '5px',
    },
    modalbutton: {
        backgroundColor: '#2e3192',
        padding: 10,
        border: 'none',
        color: 'white',
        fontWeight: 'bold',
        cursor: 'pointer',
        textAlign: 'center',
        borderRadius: '5px',
    },
    buttonsignup: {
        backgroundColor: '#f2682a',
        width: '100%',
        padding: 10,
        border: 'none',
        color: 'white',
        fontWeight: 'bold',
        cursor: 'pointer',
        textAlign: 'center',
        borderRadius: '5px',
    },
    confirmationContent: {
        textAlign: 'center',
    },
    loginSignupContent: {
        textAlign: 'center',
    },
    showModelContent: {
        textAlign: 'center',
    },
    showModelButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1rem',
        width: '100%',
    },
};

ModalComponent.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['error', 'success', 'confirmation', 'loginSignup', 'showmodel']).isRequired,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    onLogin: PropTypes.func,
    onSignup: PropTypes.func,
    title: PropTypes.string,
    message: PropTypes.string,
};

export default ModalComponent;
