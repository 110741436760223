import React, { useState, useEffect } from 'react';
// import girlone from '../assests/girlImage1.png';
// import girltwo from '../assests/girlImage2.png';
// import girlthree from '../assests/girlImage3.png';
import ellipseone from '../assests/Group1.png'
import ellipsetwo from '../assests/Group2.png'
import ellipsethree from '../assests/Group3.png'
import ellipsefour from '../assests/Group4.png'
// import mobileimage from '../assests/mobileImage.png';
// import playstoredownload from '../assests/googleplay.png';
import '../Styles/Homeviewtwo.css';
import ModalComponent from '../common/Modal';
import { useNavigate } from 'react-router-dom';

function HomeViewtwo({
    female,
}) {
    const [femaledata, setfemaledata] = useState([])
    const [modalType, setModalType] = useState(null);
    const [modalvisible, setModalVisible] = useState(false)
    const navigation = useNavigate()

    useEffect(() => {
        if (female.responseData !== undefined) {
            setfemaledata(female.responseData)
        }
    }, [female])

    const onprofileclick = () => {
        setModalType('loginSignup')
        setModalVisible(true)
    }
    const handleregister = () => {
        navigation('/register');
    };

    const handlelogin = () => {
        navigation('/login')
    }
    return (
        <div>
            <div className="col-12 homeviewtwo-container">
                <div className="card-container">
                    <div className='cardMain'>
                        <div className="ellipse">
                            <img src={ellipseone} alt='noellipse' width="70%" style={{ marginTop: '10%' }} />
                        </div>
                        <h3 style={{ marginTop: '10%' }}>Success Story</h3>
                        <p style={{ width: '90%', alignSelf: 'center' }}>
                            Discover inspiring success stories of love found and dreams fulfilled through our platform!
                        </p>
                        <div>
                            <button className="viewSuccessBtnone">View Success Story</button>
                        </div>
                    </div>
                    <div className='cardMain'>
                        <div className="ellipsetwo">
                            <img src={ellipsetwo} alt='noellipse' width="70%" style={{ marginTop: '10%' }} />
                        </div>
                        <h3 style={{ marginTop: '10%' }}>Verified Members</h3>
                        <p style={{ width: '90%', alignSelf: 'center' }}>
                            Experience seamless matchmaking with our advanced auto-match algorithm, designed to connect you with compatible partners in no time!
                        </p>
                        <div>
                            <button className="viewSuccessBtn">View Profile Now</button>
                        </div>
                    </div>
                    <div className='cardMain'>
                        <div className="ellipsethree">
                            <img src={ellipsethree} alt='noellipse' width="70%" style={{ marginTop: '10%' }} />
                        </div>
                        <h3 style={{ marginTop: '10%' }}>Search Options</h3>
                        <p style={{ width: '90%', alignSelf: 'center' }}>
                            Connect confidently with our verified members, ensuring genuine profiles and meaningful connections!
                        </p>
                        <div>
                            <button className="viewSuccessBtn">Search Now</button>
                        </div>
                    </div>
                    <div className='cardMain'>
                        <div className="ellipsefour">
                            <img src={ellipsefour} alt='noellipse' width="70%" style={{ marginTop: '10%' }} />
                        </div>
                        <h3 style={{ marginTop: '10%' }}>Matching Profiles</h3>
                        <p style={{ width: '90%', alignSelf: 'center' }}>
                            Experience seamless matchmaking with our advanced auto-match algorithm, designed to connect you with compatible partners in no time!
                        </p>
                        <div>
                            <button className="viewSuccessBtn">View Matches Story</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='hometwo-view-container'>
                <div style={{ display: 'flex', alignItems: 'center' }} className='Featured-Bride'>
                    <div style={{ display: 'flex', gap: '1rem' }} className='featured-profile'>
                        {femaledata.map((data, index) => (
                            <div key={index}>
                                <img src={data.user_profile_picture} alt='noboyimg' className='images' />
                                <div className='marginTop'>
                                    <button onClick={onprofileclick} className='viewBtn'>View Profile</button>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div style={{ alignSelf: 'center' }}>
                        <p className="featuredText">Featured Bride</p>
                        <div>
                            <span className="featuredDescription">
                                This is our featured Bride section
                                where you can check our elite profiles
                                <div>
                                    <a style={{ color: '#ff6b00', cursor: 'pointer', borderBottom: '2px solid #ff6b00', backgroundColor: 'white' }} onClick={onprofileclick}>View all</a>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="downloadSection">
                <div className="downloadText">
                    <div>
                        <h3>Download App Now !</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
                    </div>
                    <div>
                        <img className="downloadImg" src={playstoredownload} alt='Download' />
                    </div>
                </div>
                <div>
                    <img className="mobileImg" src={mobileimage} alt='Mobile' />
                </div>
            </div> */}
            <ModalComponent
                isOpen={modalvisible}
                onRequestClose={() => setModalVisible(false)}
                type={modalType}
                // onCancel={oncancel}
                title="Please Login or Register"
                onLogin={handlelogin}
                onSignup={handleregister}
            />
        </div>
    );
}

export default HomeViewtwo;
