import { takeLatest, call, put } from 'redux-saga/effects';
import axios from 'axios';
import { GET_MY_PROFILE_REQUEST } from './actionType';
import {
 getprofileSuccess,
 getprofileFailure
} from './action';
import { Api } from '../../common/Api';

function* profileSaga(action) {
    const params = {
        matri_id : action.payload.matri_id
    }
    try {
        const response = yield call(axios.post, `${Api.Apiurl}myProfile.php`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        yield put(getprofileSuccess(response.data));
    } catch (error) {
        yield put(getprofileFailure(error.message));
    }
  }

  export function* watchprofile() {
    yield takeLatest(GET_MY_PROFILE_REQUEST, profileSaga);
  }