import React from 'react'
import Homefooter from '../../Homefooter'
import logo from '../../../assests/logo.png';
import { useNavigate } from 'react-router-dom';

function PrivacyPolicy() {
  const navigate = useNavigate()
    const onclickicon = () => {
        navigate('/')
    }
  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        <div style={{ cursor: 'pointer' }}>
          <img src={logo} alt='Logo' onClick={onclickicon} style={{ width: '25%' }} />
        </div>
      </div>
      <p>PrivacyPolicy here!</p>
      <Homefooter />
    </div>
  )
}

export default PrivacyPolicy
