import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import HomeViewone from '../HomeViewone';
import HomeViewtwo from '../HomeViewtwo';
import Homefooter from '../Homefooter';
// import instagramimage from '../../assests/Instagram.png';
// import twitterimage from '../../assests/Twitter.png';
import logo from '../../assests/logo.png';
import '../../Styles/Home.css'
import Header from './Header';
import Pagination from '../../common/Pagination';
import ModalComponent from '../../common/Modal';
// import Register from './Register/Register';

function Home({
    religion,
    language,
    handleReligionChange,
    handlelanguageChange,
    religionid,
    languageId,
    caste,
    handlecasteChange,
    casteid,
    handlegenderchange,
    searchRequest,
    gender,
    searchdata,
    maledata,
    femaledata,
    onclickmodal,
}) {
    const [ageFrom, setAgeFrom] = useState("");
    const [ageTo, setAgeTo] = useState("");
    const [religionData, setreligionData] = useState([]);
    const [languageData, setlanguageData] = useState([]);
    const [casteData, setcasteData] = useState([]);
    const [searchData, setsearchData] = useState([]);
    const [modalType, setModalType] = useState(null);
    const [modalvisible, setModalVisible] = useState(false)
    const ages = Array.from({ length: 43 }, (_, i) => i + 18);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 12;

    const totalPages = Math.ceil(searchData.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = searchData.slice(startIndex, endIndex);

    const navigate = useNavigate();
    const handlelogin = () => {
        navigate('/login');
    };
    const hangleregister = () => {
        console.log('register')
        navigate('/register');
    };

    useEffect(() => {
        if (religion !== undefined) {
            setreligionData(Object.values(religion));
        }
        if (language !== undefined) {
            setlanguageData(language);
        }
        if (caste !== undefined) {
            setcasteData(caste);
        }
        if (searchdata !== undefined) {
            setsearchData(searchdata);
        }
    }, [religion, language, caste, searchdata]);

    const handlesubmit = () => {
        setModalType('loginSignup')
        setModalVisible(true)
        // searchRequest({
        //     gender,
        //     fromage: ageFrom,
        //     toage: ageTo,
        //     language: languageId,
        //     religion: religionid,
        //     caste: casteid
        // });
    };
    // console.log(handlesubmit)
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    const onprofileclick = () => {
        navigate('/register')
    }

    const onclickicon = () =>{
        navigate('/')
    }
    return (
        <div>
            {/* <div className='col-12 header-container'>
                <div style={{ display:'flex', justifyContent:'space-between',flexWrap:'wrap', padding:'0.4%' }}>
                    <div className='col-6 header-text'>
                        <p>Make sure to spoil the mom figures, teachers & grads in your life by shopping small!</p>
                    </div>
                    <div className='col-2'>
                        <div className='logo-container'>
                            <div>
                                <img width="100%" src={instagramimage} alt='Instagram' />
                            </div>
                            <div>
                                <img width="100%" src={instagramimage} alt='Instagram' />
                            </div>
                            <div>
                                <img width="100%" src={twitterimage} alt='Twitter' />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <Header />
            <div className='col-12'>
                <div className='button-container'>
                    {/* <div className='col-3 d-flex justify-content-around navigation-container'>
                        <span onClick={() => {navigate('myhome')}}>Home</span>
                        <span>Search</span>
                        <span>Membership</span>
                    </div> */}
                    <div className='logo-img-move'>
                        <img src={logo} alt='Logo' onClick={onclickicon} className='logo-img' />
                    </div>
                    <div className='col-3 headtwo-btn'>
                        <div>
                            <button onClick={onclickmodal} className='register-btn'>Register</button>
                        </div>
                        <div>
                            <button onClick={handlelogin} className='login-btn-home'>Login</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='background-img'>
                <div className='background-img-before'></div>
                <div className='center-content'>
                    <span className='center-content-text'>Find your perfect partner<br />for life</span>
                    <button onClick={onclickmodal} className='center-content-button'>Get started</button>
                </div>
            </div>
            <div className='col-12'>
                <div className='col-10 search-container'>
                    <div style={{ textAlign: 'center' }} className='col-2'>
                        <div className='search-text'>
                            <span className='search-container-text'>SEARCH YOUR</span>
                            <span className='match-text'>MATCH</span>
                        </div>
                    </div>
                    <div style={{ width: '7%' }} className='col-1'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <input
                                type="radio"
                                id="bride"
                                name="gender"
                                value="Male"
                                onChange={handlegenderchange}
                            />
                            <label htmlFor="bride">BRIDE</label>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <input
                                type="radio"
                                id="groom"
                                name="gender"
                                value="Female"
                                onChange={handlegenderchange}
                            />
                            <label htmlFor="groom">GROOM</label>
                        </div>
                    </div>
                    <div>
                        <div>
                            <span>Age</span>
                        </div>
                        <div style={{ display: 'flex', gap: '0.3rem' }}>
                            <select value={ageFrom} onChange={(e) => setAgeFrom(e.target.value)} className='col-6 custom-select-age'>
                                {ages.map(age => (
                                    <option key={age} value={age}>{age}</option>
                                ))}
                            </select>
                            <div style={{ marginTop: '5%' }}>
                                <span>To</span>
                            </div>
                            <select value={ageTo} onChange={(e) => setAgeTo(e.target.value)} className='col-6 custom-select-age'>
                                {ages.map(age => (
                                    <option key={age} value={age}>{age}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div style={{ marginLeft: '2%' }}>
                        <div>
                            <span>Language</span>
                        </div>
                        <select value={languageId} onChange={handlelanguageChange} className='custom-select'>
                            <option value="">Select your Language</option>
                            {languageData.map((item) => (
                                <option key={item.mtongue_id} value={item.mtongue_id}>
                                    <span>{item.mtongue_name}</span>
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <div>
                            <span>Religion</span>
                        </div>
                        <select value={religionid} onChange={handleReligionChange} className='custom-select'>
                            <option value="">Select your religion</option>
                            {religionData.map((item) => (
                                <option key={item.religion_id} value={item.religion_id}>
                                    {item.religion_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <div>
                            <span>Caste</span>
                        </div>
                        <select value={casteid} onChange={handlecasteChange} className='custom-select'>
                            <option value="">Select your Caste</option>
                            {casteData.map((item) => (
                                <option key={item.caste_id} value={item.caste_id}>
                                    {item.caste_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <div style={{ display: 'flex', marginTop: '15%' }}>
                            <button className='let-btn' onClick={onclickmodal}>Let's begin</button>
                        </div>
                    </div>
                </div>
            </div>
            {searchData.length > 0 ? (
                <>
                    <div className='search-container-result'>
                        {currentItems.map((search) => (
                            <div key={search.profile_id} className='search-image-container'>
                                <div className='profile-container'>
                                    <img src={search.user_profile_picture} alt="Profile" className="search-profile blur-image" />
                                    <div className='profile-info'>
                                        <span style={{ fontWeight: 'bold' }}>{search.profile_id}</span>
                                        <span style={{ display: 'block' }}>{search.age} years old</span>
                                        <span style={{ display: 'block' }}>{search.profile_text}</span>
                                    </div>
                                    <div className='marginTop'>
                                        <button className='search-view-btn'>
                                            View Profile
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </>
            ) : (
                <div>
                    <div>
                        <HomeViewone
                            male={maledata}
                        />
                    </div>
                    <div>
                        <HomeViewtwo
                            female={femaledata}
                        />
                    </div>
                </div>
            )}
            <div>
                <Homefooter />
            </div>
            <ModalComponent
                isOpen={modalvisible}
                onRequestClose={() => setModalVisible(false)}
                type={modalType}
                // onCancel={oncancel}
                title="Please Login or Register"
                onLogin={handlelogin}
                onSignup={hangleregister}
            />
        </div>
    );
}

export default Home;
