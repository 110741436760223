import React, { useState, useEffect } from 'react';
import Registermodal from './Registermodal';
import './StepOne.css';

function StepOne({
  steponemodalopen,
  stepconelosemodal,
  religion,
  handlereligionChange,
  religionid,
  caste,
  subcaste,
  language,
  Gotra,
  caste_id,
  handlecasteChange,
  subcasteid,
  handleSubcasteChange,
  Gotra_id,
  handleGotraChange,
  languageId,
  profile_id,
  handlelanguageChange,
  signupstepone,
}) {

  // console.log('religionid', religionid)
  const [selectedProfile, setSelectedProfile] = useState("");
  const[Materialstatus, setMaterialstatus] = useState('')
  const [selectedGender, setSelectedGender] = useState("");
  const [Continue, setContinue] = useState(false);
  const [Subcastedata, setSubcastedata] = useState([]);
  const [castedata, setcastedata] = useState([]);
  const [Gotradata, setGotradata] = useState([]);
  const [step, setstep] = useState(1);

  useEffect(() => {
    if (caste !== undefined) {
      setcastedata(caste);
    }
    if (subcaste !== undefined) {
      setSubcastedata(subcaste);
    }
    if (Gotra !== undefined) {
      setGotradata(Gotra);
    }
  }, [subcaste, caste, Gotra]);

  const profileOptions = [
    "Myself",
    "My Son",
    "My Daughter",
    "My Brother",
    "My Sister",
    "My Friend",
    "My Relative",
  ];

  const materialstatus = [
    "Never Married",
    "Divorced",
    "Widowed",
    "Awaiting Divorce",
  ];

  const genderOptions = ["Male", "Female"];

  useEffect(() => {
    switch (selectedProfile) {
      case "My Son":
      case "My Brother":
        setSelectedGender("Male");
        break;
      case "My Daughter":
      case "My Sister":
        setSelectedGender("Female");
        break;
      default:
        setSelectedGender("");
        break;
    }
  }, [selectedProfile]);

  const onContinue = () => {
    if(selectedProfile === ''){
      alert('Please select a profile type')
      return;
    }
    if(selectedGender === ''){
      alert('Please select a gender')
      return;
    }
    setContinue(true);
  };

  const onPageContinue = () => {
    if(Materialstatus === ''){
      alert('Please select your material status')
      return;
    }
    if(religionid === 0){
      alert('Please select your religion')
      return;
    }
    if(caste_id === 0){
      alert('Please select your caste')
      return;
    }
    setstep(2);
  };
 const Steponesubmit  = () =>{
  if(subcasteid === 0){
    alert('Please select your subcaste')
    return;
  }
  if(Gotra_id === 0){
    alert('Please select your gotra')
    return;
  }
  if(languageId === 0){
    alert('Please select your Language')
    return;
  }
  signupstepone({
    profileby:selectedProfile,
    gender:selectedGender,
    m_status:Materialstatus,
    religion:religionid,
    caste:caste_id,
    subcaste:subcasteid,
    gothra:Gotra_id,
    m_tongue:languageId,
    profile_id,
  })
 }
  const content = (
    <div className="modal-content">
      {!Continue && (
        <>
          <p style={{ fontWeight: 'bold', color: '#368005' }}>This Profile is for</p>
          <div className="profile-options">
            {profileOptions.map((option) => (
              <button
                key={option}
                className={`profile-option ${selectedProfile === option ? "selected" : ""}`}
                onClick={() => setSelectedProfile(option)}
              >
                {option}
              </button>
            ))}
          </div>

          <h3>Gender</h3>
          <div className="gender-options" style={{ marginBottom: '5%' }}>
            {genderOptions.map((gender) => (
              <button
                key={gender}
                className={`gender-option ${selectedGender === gender ? "selected" : ""}`}
                onClick={() => setSelectedGender(gender)}
              >
                {gender}
              </button>
            ))}
          </div>
          <button style={{ backgroundColor: '#f2682a' }} className="submit-modal-button" onClick={onContinue}>
            Continue
          </button>
        </>
      )}

      {Continue && step === 1 && (
        <>
          <div>
            <p style={{ fontWeight: 'bold', color: '#368005' }}>Material Status</p>
            <div className="profile-options">
              {materialstatus.map((option) => (
                <button
                  key={option}
                  className={`profile-option ${Materialstatus === option ? "selected" : ""}`}
                  onClick={() => setMaterialstatus(option)}
                >
                  {option}
                </button>
              ))}
            </div>
          </div>
          <div style={{ marginTop: '2%' }}>
            <div>
              <span>Religion</span>
            </div>
            <select value={religionid} className="religion-dropdown" onChange={handlereligionChange} style={{ width: '100%' }}>
              {religion.map((item) => (
                <option key={item.religion_id} value={item.religion_id}>
                  <span style={{ color: 'black' }}>{item.religion_name}</span>
                </option>
              ))}
            </select>
          </div>
          <div style={{ marginTop: '2%' }}>
            <div>
              <span>Select caste</span>
            </div>
            <select value={caste_id} onChange={handlecasteChange} className="religion-dropdown" style={{ width: '100%' }}>
              {castedata.map((item) => (
                <option key={item.caste_id} value={item.caste_id}>
                  <span style={{ color: 'black' }}>{item.caste_name}</span>
                </option>
              ))}
            </select>
          </div>
          <button style={{ marginTop: '2%' }} className="submit-modal-button" onClick={onPageContinue}>
            Continue
          </button>
        </>
      )}

      {Continue && step === 2 && (
        <>
          <div style={{ marginTop: '2%' }}>
            <div>
              <span>Select Subcaste</span>
            </div>
            <select value={subcasteid} onChange={handleSubcasteChange} className="religion-dropdown" style={{ width: '100%' }}>
              {Subcastedata.map((item) => (
                <option key={item.sub_caste_id} value={item.sub_caste_id}>
                  <span style={{ color: 'black' }}>{item.sub_caste_name}</span>
                </option>
              ))}
            </select>
          </div>
          <div style={{ marginTop: '2%' }}>
            <div>
              <span>Select Gotra</span>
            </div>
            <select value={Gotra_id} onChange={handleGotraChange} className="religion-dropdown" style={{ width: '100%' }}>
              {Gotradata.map((item) => (
                <option key={item.id} value={item.id}>
                  <span style={{ color: 'black' }}>{item.gotra}</span>
                </option>
              ))}
            </select>
          </div>
          <div style={{ marginTop: '2%' }}>
            <div>
              <span>Select Language</span>
            </div>
            <select value={languageId} onChange={handlelanguageChange} className="religion-dropdown" style={{ width: '100%' }}>
              {language.map((item) => (
                <option key={item.mtongue_id} value={item.mtongue_id}>
                  <span style={{ color: 'black' }}>{item.mtongue_name}</span>
                </option>
              ))}
            </select>
          </div>
          <button style={{ marginTop: '2%' }} onClick={Steponesubmit} className="submit-modal-button">
            Submit
          </button>
        </>
      )}
    </div>
  );

  return (
    <Registermodal
      isOpen={steponemodalopen}
      onClose={stepconelosemodal}
      title="Step One (Genreal info)"
      content={content}
    />
  );
}

export default StepOne;
