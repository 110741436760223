import { connect } from "react-redux";
import { withRouter } from "../../common/withRouter";
import Loginclass from "./Login";
import { loginRequest, OtpRequest, OtpVerifyRequest } from "./action";

const mapStateToProps = (state) => {
  // console.log('loginotpstate=======>', state.login.otp);
  // console.log('loginveifystate=======>', state.login.loginwithotp);
  return {
    ...state.login,
    user: state.login.user,
    otpstatus: state.login.otp.status,
    verifyotpstatus:state.login.loginwithotp,
    otpmessage:state.login.otp.message
  };
};

const mapDispatchToProps = (dispatch) => ({
  login: (credentials) => dispatch(loginRequest(credentials)),
  otprequest: (params) => dispatch(OtpRequest(params)),
  verifyOtp: (params) => dispatch(OtpVerifyRequest(params)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Loginclass));
