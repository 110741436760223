import React from 'react'
import logo from '../../../assests/logo.png';
import Homefooter from '../../Homefooter'
import { useNavigate } from 'react-router-dom';

function Aboutus() {
    const navigate = useNavigate()
    const onclickicon = () => {
        navigate('/')
    }
    return (
        <div>
            <div style={{  textAlign: 'center' }}>
                <div style={{ cursor:'pointer' }}>
                    <img src={logo} alt='Logo' onClick={onclickicon} style={{ width: '25%' }} />
                </div>
            </div>
            <p>About us here!</p>
            <Homefooter />
        </div>

    )
}

export default Aboutus
