// reducers.js

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  OTP_REQUEST,
  OTP_SUCCESS,
  OTP_FAILURE,
  OTP_VERIFY_REQUEST,
  OTP_VERIFY_SUCCESS,
  OTP_VERIFY_FAILURE
} from "./actionType";

const initialState = {
  loading: false,
  user: [],
  error: null,

  Otploading: false,
  otp:[],
  otperror:null,

  OtpVerifyloading: false,
  loginwithotp:[],
  verifyotperror:null
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      case OTP_REQUEST :
      return{
        ...state,
        Otploading:true,
        otperror:null
      }
      case OTP_SUCCESS :
        return{
          ...state,
          Otploading: false,
          otp: action.payload,
          otperror:null
        }
        case OTP_FAILURE :
          return{
            ...state,
            Otploading:false,
            otperror :action.payload
          }

          case OTP_VERIFY_REQUEST:
            return{
              ...state,
              OtpVerifyloading: true,
              verifyotperror:null
            }
            case OTP_VERIFY_SUCCESS :
              return{
                ...state,
                OtpVerifyloading: false,
                loginwithotp:action.payload,
                verifyotperror:null,
              }
              case OTP_VERIFY_FAILURE:
                return{
                  ...state,
                  OtpVerifyloading: false,
                  verifyotperror:action.payload
                }
    default:
      return state;
  }
};

export default loginReducer;
